import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';

// create slice

const name = 'creditcard';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const creditcardActions = { ...slice.actions, ...extraActions };
export const creditcardsReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        creditcards: {},
        defaultValue: {}
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/user`;

    return {
        getAll: getAll()
    };    

    function getAll() {
        return createAsyncThunk(
            `${name}/getAll`,
            async () => await fetchWrapper.get(`${baseUrl}/GetCreditCards`)
        );
    }
}

function createExtraReducers() {
    return {
        ...getAll()
    };

    function getAll() {
        var { pending, fulfilled, rejected } = extraActions.getAll;
        return {
            [pending]: (state) => {
                state.creditcards = { loading: true };
            },
            [fulfilled]: (state, action) => {
               // state.creditcards = action.payload;

                //test 

                
                    state.creditcards = action.payload?.sort((a, b) => {
                    if (a.cc_active === true && b.cc_active === false) {
                      return -1; // a should come before b
                    }
                    if (a.cc_active === false && b.cc_active === true) {
                      return 1; // a should come after b
                    }
                    return 0; // no sorting needed
                  });
                  

                //test
                const authUser = JSON.parse(localStorage.getItem('user'));
                  state.defaultValue = action.payload.find(obj=>{
          return obj.cc_user_id == authUser.user.user_id
         })
         if (state.defaultValue){
         localStorage.setItem('ccValue', JSON.stringify(state.defaultValue));
         } else {
            const obj={cc_active: '', cc_id:'', cc_name_on_card: '', cc_nbr:'Select...',cc_user_id:''};
            // const obj ={}
         localStorage.setItem('ccValue', JSON.stringify(obj));


         }
            },
            [rejected]: (state, action) => {
                state.creditcards = { error: action.error };
            }
        };
    }
}
