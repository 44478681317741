import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './MissingReceiptsFilterDashboard.scss';
import { creditcardActions } from '../../_store';

import Select from 'react-select';
import { Navigate } from 'react-router-dom';
import { history } from '../../_helpers';
import CurrencyInput from '../../_components/CurrencyInput.tsx'
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import { missingReceiptsDashboardActions } from "../../_store";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Spinner from 'react-bootstrap/Spinner';



const billTo = [
  { value: 'C', label: 'Client', key: 1 },
  { value: 'T', label: 'Tek', key: 2 },
  { value: 'P', label: 'Personal', key: 3 }
]
const creditDD = [
  { value: 'Charge', label: 'Charge', key: true },
  { value: 'Credit', label: 'Credit', key: false },
  { value: 'null', label: '', key: null },
]
const defaultType = {
  value: null, label: '', key: null
}
export { MissingReceiptsFilterDashboard };

function MissingReceiptsFilterDashboard(props) {
  useEffect(() => {

    dispatch(creditcardActions.getAll());

  }, []);
  const dispatch = useDispatch();
  const { user: authUser } = useSelector(x => x.auth);
  const { creditcards } = useSelector(x => x.creditcards);

  const [clientExpTaskLevel, setClientExpTaskLevel] = useState(null);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();


  const ccRef = useRef();
  const typeRef = useRef();
  const tDateFromRef = useRef();
  const tDateToRef = useRef();


  if (!authUser) {
    return <Navigate to="/login" state={{ from: history.location }} />
  }
  let creditDefaultOption = { cc_active: '', cc_id: '', cc_name_on_card: '', cc_nbr: 'Select...', cc_user_id: '' }

  if (localStorage.getItem('ccValue')) {
    creditDefaultOption = JSON.parse(localStorage.getItem('ccValue'));
  }

  let bindFilterObjValues;
  bindFilterObjValues = JSON.parse(localStorage.getItem('missingReceiptsFilterValues'));
  if (creditcards.length > 0) {
    creditDefaultOption = creditcards.find(obj => {
      return obj.cc_id === bindFilterObjValues.cc_id;
    })
  }


  const transStartDate = (data) => {
    setStartDate(data);
    if (data !== null) {
      inputField.cc_trans_from_dt = moment(data).format('MM/DD/YYYY')
    } else {
      inputField.cc_trans_from_dt = null;
    }
  }

  const transEndDate = (data) => {
    setEndDate(data);
    if (data !== null) {
      inputField.cc_trans_to_dt = moment(data).format('MM/DD/YYYY')
    } else {
      inputField.cc_trans_to_dt = null;
    }
  }



  const [inputField, setInputField] = useState({
    cc_id: bindFilterObjValues.cc_id ? bindFilterObjValues.cc_id : '',

    cc_trans_from_dt: startDate ? moment(startDate).format('MM/DD/YYYY') : bindFilterObjValues.cc_trans_from_dt,
    cc_trans_to_dt: endDate ? moment(endDate).format('MM/DD/YYYY') : bindFilterObjValues.cc_trans_to_dt,

    cc_trans_amt: bindFilterObjValues.cc_trans_amt,

    sortColumn: bindFilterObjValues.sortColumn,
    sortOrder: bindFilterObjValues.sortOrder,
    isCharge: bindFilterObjValues.isCharge !== null ? bindFilterObjValues.isCharge : null,
    pageNumber: 1,
    pageSize: 25,
    exportExcel: false,

  });
  const onChangeGetValue = e => {
    const { name, value } = e.target;
    setInputField(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
  const amountChange = (e) => {
    let amountString = e.target.value;
    const amountStringValue = amountString?.slice(1);
    // inputField.cc_trans_amt = amountStringValue;
    if (inputField.isCharge == true || inputField.isCharge == null) {
      inputField.cc_trans_amt = amountStringValue ? Math.abs(amountStringValue) : null;
    } else {
      inputField.cc_trans_amt = amountStringValue
        ? (!String(amountStringValue).trim().startsWith('-') ? '-' + String(amountStringValue).trim() : String(amountStringValue).trim())
        : null;
    }
  }
  let copyLocalObj = {};
  const handleSubmit = (event) => {
    event.preventDefault();
    props.sdata.onHide();
    if( inputField.isCharge || inputField.isCharge == null) {
      inputField.cc_trans_amt = inputField.cc_trans_amt ? Math.abs(inputField.cc_trans_amt) : inputField.cc_trans_amt;
    } else{
      inputField.cc_trans_amt = inputField.cc_trans_amt 
      ? (!String(inputField.cc_trans_amt).trim().startsWith('-') ? '-' + String(inputField.cc_trans_amt).trim() : String(inputField.cc_trans_amt).trim()) 
      : null;
    }
    localStorage.setItem('missingReceiptsFilterValues', JSON.stringify(inputField));
    props.sdata.setcurrentpage(1);
    props.sdata.setrecordsperpage(25)
    copyLocalObj = Object.assign({}, JSON.parse(localStorage.getItem('missingReceiptsFilterValues')));
    localStorage.setItem('missingReceiptsCopyLocalObj', JSON.stringify(copyLocalObj));
    dispatch(missingReceiptsDashboardActions.getAll(inputField));
    inputField.pageNumber = '';
    inputField.pageSize = '';
  }

  const cancelModal = () => {
    props.sdata.onHide();
    if (localStorage.getItem('missingReceiptsCopyLocalObj')) {
      const resetForm = JSON.parse(localStorage.getItem('missingReceiptsCopyLocalObj'))
      localStorage.setItem('missingReceiptsFilterValues', JSON.stringify(resetForm));
    }
  }
  const resetFilter = () => {
    const ccObj = JSON.parse(localStorage.getItem('ccValue'));

    let Filterobj = {
      cc_id: '',
      cc_trans_from_dt: null,
      cc_trans_to_dt: null,
      cc_trans_amt: '',
      isCharge: null,
      sortColumn: '',
      sortOrder: '',
      pageNumber: 1,
      pageSize: 25,
      exportExcel: false
    }


    ccRef.current.setValue(null);
    typeRef.current.setValue(null);
    setStartDate(null);
    setEndDate(null);



    setInputField(prevInputField => ({
      ...prevInputField,
      isCharge: null,
      cc_id: '',
      cc_trans_from_dt: null,
      cc_trans_to_dt: null,

      cc_trans_amt: ''

    }));

    localStorage.setItem('missingReceiptsFilterValues', JSON.stringify(Filterobj));
    bindFilterObjValues = JSON.parse(localStorage.getItem('missingReceiptsFilterValues'));
  }
  const getOptionLabel = (option) => {
    if (option.cc_active === false) {
      return (
        <div className='gray-bg common-bg'>
          {option.cc_nbr}
        </div>
      );
    } else if (option.cc_active === true) {
      return (
        <div className='white-bg common-bg'>
          {option.cc_nbr}
        </div>
      );
    } else {
      return (
        <div className='gray-bg-select'>
          {option.cc_nbr}
        </div>
      );
    }
  }
  return (

    <div className="form-overlay">

      <div className="receipts edit-receipts">
        {(creditcards.loading) &&
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>}

        <form onSubmit={handleSubmit}>
          <div className='filter-dashboard'>
            {creditcards.length &&

              <div>
                <div className="field-group second-section">
                  <div className="field-element field-element-type">
                    <div className="field">
                      <div className="label">Type</div>
                      {creditDD.length > 0 &&
                        <Select options={creditDD}
                          isClearable
                          getOptionLabel={e => e.label}
                          getOptionValue={e => e.key}
                          defaultValue={creditDD.find(obj => obj.key == (bindFilterObjValues.isCharge !== null ? bindFilterObjValues.isCharge : null))}
                          isSearchable={false}
                          className="form-custom-class cc-class"
                          classNamePrefix="form-custom-class-prefix" name="isCharge"
                          ref={typeRef}

                          onChange={data => onChangeGetValue({ target: { value: data?.key, name: 'isCharge' } })}
                        />
                      }
                    </div>
                  </div>
                  <div className="field-element">
                    <div className="field cc-dropdown">
                      <div className="label">Credit Card</div>
                      {creditcards.length > 0 &&
                        <Select options={creditcards}
                          isClearable
                          //getOptionLabel={e => e.cc_nbr}
                          getOptionValue={e => e.cc_id}
                          defaultValue={creditcards.find(obj => obj.cc_id == bindFilterObjValues.cc_id)}
                          isSearchable={false}
                          className="form-custom-class cc-class"
                          classNamePrefix="form-custom-class-prefix" name="cc_id"
                          ref={ccRef}
                          getOptionLabel={getOptionLabel}
                          onChange={data => onChangeGetValue({ target: { value: data?.cc_id, name: 'cc_id' } })}
                        />
                      }
                    </div>
                  </div>

                </div>
                <div className="field-group second-section" key="payee">
                  <div className="field-element">
                    <div className="field input-margin">
                      <label htmlFor="cc_trans_from_dt" className="label">Transaction Date Range</label>
                      <div className='d-range'>
                        <DatePicker
                          onChange={(date) => transStartDate(date)}
                          selectsStart
                          startDate={startDate}
                          maxDate={new Date()}
                          className='fm-date'
                          id="test"
                          selected={startDate ? startDate : (bindFilterObjValues.cc_trans_from_dt ? new Date(bindFilterObjValues.cc_trans_from_dt) : null)}
                          ref={tDateFromRef}
                        />
                        <DatePicker
                          selected={endDate ? endDate : (bindFilterObjValues.cc_trans_to_dt ? new Date(bindFilterObjValues.cc_trans_to_dt) : null)}
                          onChange={(date) => transEndDate(date)}
                          selectsEnd
                          maxDate={new Date()}
                          endDate={endDate}
                          minDate={startDate}
                          className='to-date'
                          ref={tDateToRef}
                        />
                      </div>
                    </div>
                  </div>


                  <div className="field-element">
                    <div className="field">
                      <label htmlFor="cc_trans_amt" className="label">Amount</label>
                      <CurrencyInput placeholder="$ 0.00" id="input-example" name="cc_trans_amt"
                        type="text" onChange={amountChange}
                        defaultValue={bindFilterObjValues.cc_trans_amt ? bindFilterObjValues.cc_trans_amt : ''}
                      />
                    </div>
                  </div>

                </div>



              </div>
            }
          </div>
          <div className='flex'>
            <OverlayTrigger
              key='bottom-filter'
              placement='bottom'
              overlay={
                <Tooltip id='tooltip-filter'>
                  Apply Filters
                </Tooltip>
              }
            >
              <Button type="submit"> <img src={'/assets/icons/checkmark.svg'} /></Button>
            </OverlayTrigger>
            <OverlayTrigger
              key='bottom-cancel'
              placement='bottom'
              overlay={
                <Tooltip id='tooltip-cancel'>
                  Cancel
                </Tooltip>
              }
            >
              <Button onClick={cancelModal} className="close-button"> <img src={'/assets/icons/close.svg'} /></Button>
            </OverlayTrigger>
            <OverlayTrigger
              key='bottom-reset'
              placement='bottom'
              overlay={
                <Tooltip id='tooltip-reset'>
                  Reset
                </Tooltip>
              }
            >
              <Button onClick={resetFilter} type="reset" defaultValue="Reset" className="reset-button"> <img src={'/assets/icons/reset.svg'} /></Button>
            </OverlayTrigger>
          </div>
        </form>
      </div>
    </div>
  );
}

