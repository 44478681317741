import Button from 'react-bootstrap/Button';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import {EditReceipt} from '../TekCCReceipts/EditReceipt/EditReceipt';
import {MileageReimbursementEditReceipt} from '../MileageReimbursement/MileageReimbursementEditReceipt/MileageReimbursementEditReceipt';
import {ReimbursementEditReceipt} from '../ExpenseReimbursement/ExpenseReimbursementEditReceipt/ExpenseReimbursementEditReceipt';
import { AddMissingReceipt } from '../MissingReceipts/AddMissingReceipt/AddMissingReceipt';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Header } from '../layouts/Header';
export function FormModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='form-modal'
    >
         <Header />
      {/* <Modal.Header closeButton> */}
        {/* <Modal.Title id="contained-modal-title-vcenter">
         Edit Receipt
        </Modal.Title> */}
      {/* </Modal.Header> */}
      
      <Modal.Body>
        {/* <h4>Centered Modal</h4> */}
         {props.mileagereimbursement ? 
           <MileageReimbursementEditReceipt formProps = {props}></MileageReimbursementEditReceipt> :
           props.reimbursement ?  <ReimbursementEditReceipt formProps = {props}></ReimbursementEditReceipt> :
           props.missingReceipts ? <AddMissingReceipt formProps = {props}></AddMissingReceipt> :
        <EditReceipt formProps = {props}></EditReceipt> }
        {/* <div className='flex'>
        <OverlayTrigger
                                key='bottom-update'
                                placement='bottom'
                                overlay={
                                    <Tooltip id='tooltip-bottom'>
                                       Update
                                    </Tooltip>
                                }
                               >
                                 <Button onClick={props.onHide}> <img src={'/assets/icons/checkmark.svg'} /></Button>
                             
                            </OverlayTrigger>
                            <OverlayTrigger
                                key='bottom-cancel'
                                placement='bottom'
                                overlay={
                                    <Tooltip id='tooltip-bottom'>
                                        Cancel
                                    </Tooltip>
                                }
                               >
                         <Button onClick={props.onHide} className="close-button"> <img src={'/assets/icons/close.svg'} /></Button>
                             
                            </OverlayTrigger>
        </div> */}
        {/* <p>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </p> */}
      {/* <Modal.Header closeButton> </Modal.Header> */}
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
}
