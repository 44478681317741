import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './validationForm.css';
import { userActions } from '../_store';
import { creditcardActions } from '../_store';
import { clientsActions } from '../_store';
import { payeeActions } from '../_store';
import { projectsActions } from '../_store';
import { tasksActions } from '../_store';
import { subtasksActions } from '../_store';
// import { uploadActions } from '../_store';
import { saveReceiptsActions } from '../_store';
import Select from 'react-select';
import { Navigate, useNavigate } from 'react-router-dom';
import ImageUploading from 'react-images-uploading';
import { history } from '../_helpers';
import CurrencyInput from 'react-currency-input-field';
// import CurrencyInput from '../_components/CurrencyInput.tsx'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const billTo = [
  { value: 'C', label: 'Client', key:1},
  { value: 'T', label: 'Tek', key:2},
  { value: 'P', label: 'Personal',key:3}
]


export { ValidationForm };

function ValidationForm() {
  useEffect(() => {
    dispatch(userActions.getAll());
    dispatch(creditcardActions.getAll());
    dispatch(clientsActions.getAll());
    dispatch(payeeActions.getAll());
  }, []);
  const dispatch = useDispatch();
  const { user: authUser } = useSelector(x => x.auth);
  const { creditcards } = useSelector(x => x.creditcards);
  const { users } = useSelector(x => x.users);
  const { payee } = useSelector(x => x.payee);
  const { clients } = useSelector(x => x.clients);
  let { projects } = useSelector(x => x.projects);
  let { tasks } = useSelector(x => x.tasks);
  let { subtasks } = useSelector(x => x.subtasks);
  const [ticketNumber, setTicketNumber] = useState('');
  const [billOption, selectBillTo] = useState();
  const [clientOption, setClient] = useState();
  const [projectOption, setProject] = useState();
  const [taskOption, setTask] = useState();
  const [clientExpTaskLevel, setClientExpTaskLevel] = useState();
  const [subTaskId, setSubTaskId] = useState();
  // let creditDefaultOption = {};
  let creditDefaultOption = { cc_active: '', cc_id: '4', cc_name_on_card: '', cc_nbr: 'Select...', cc_user_id: '' }
  if (!authUser) {
    return <Navigate to="/login" state={{ from: history.location }} />
  }
  if (localStorage.getItem('ccValue')) {
    creditDefaultOption = JSON.parse(localStorage.getItem('ccValue'));
  }
  const userDefaultValue = {
    user_active: authUser.user.user_active,
    user_fname: authUser.user.user_fname,
    user_id: authUser.user.user_id,
    user_lname: authUser.user.user_lname,
    user_mname: authUser.user.user_mname
  }
  const selectClient = (e) => {
    if (e) {
      
        setClientExpTaskLevel(e.client_exp_at_task_level);

      const param = e.client_id;
      dispatch(projectsActions.getAllById({param}));
      setClient(e.client_id);
      // if (billOption == 1) {
      //   onChangeGetValue({ target: { value: e?.client_id, name: 'cc_trans_bill_to_client_id' } })
      // }
    } else {
      projects = [];
      tasks=[];
      setClient(undefined)
    }
  };
  const selectPersonal = (e) => {
    if (e) {
      // if (billOption == 3) {
      //   onChangeGetValue({ target: { value: e?.user_id, name: 'cc_trans_bill_to_user_id' } })
      // }
    }
  };
  /// date

  //// Schema validation

 const schema=Yup.object().shape({
    // showEmail: Yup.boolean(),
         cc_trans_bill_to: Yup
      .object()
      .shape({
        value: Yup.string().required("Bill To is required"),
        label: Yup.string().required("Bill To gg sis required"),
      })
      .nullable()
      .required("Bill To is required"),
      cc_trans_bill_to_client_id: Yup
      .object()
      .shape({
        client_name: Yup.string(),
        client_id: Yup.string(),
      })
      .nullable()
      .when('cc_trans_bill_to', {
        is: {value: 'C', label: 'Client', key: 1},
        then:  Yup
        .object()
        .shape({
          client_name: Yup.string().required("Bill To is required"),
          client_id: Yup.string().required("Bill To is required"),
        })
        .nullable()
        .required("Bill To is required"),
      })
  })

  // const schema = Yup.object().shape({
  //   showEmail: Yup.boolean(true),
  //   cc_id: Yup
  //     .object()
  //     .shape({
  //       cc_nbr: Yup.string().required("creditCard is required"),
  //       cc_id: Yup.string().required("creditCard is required"),
  //     })
  //     .nullable()
  //     .required("creditCard is required"),
  
  //   userId: Yup
  //     .object()
  //     .shape({
  //       user_id: Yup.string().required("userId is required"),
  //     })
  //     .nullable()
  //     .required("userId is required"),
  
  //     cc_trans_vendor_id: Yup
  //     .object()
  //     .shape({
  //       cc_trans_vendor_name: Yup.string().required("vendor is required"),
  //       cc_trans_vendor_id: Yup.string().required("vendor is required"),
  //     }).nullable()
  //     .required("vendor is required"),
  
  //     cc_trans_bill_to: Yup
  //     .object()
  //     .shape({
  //       value: Yup.string().required("Bill To is required"),
  //       label: Yup.string().required("Bill To gg sis required"),
  //     })
  //     .nullable()
  //     .required("Bill To is required"),
   
  //     // cc_trans_bill_to_client_id: Yup
  //     // .object()
  //     // .shape({
  //     //   client_name: Yup.string().required("Client is required"),
  //     //   client_id: Yup.string().required("Client is required"),
  //     //   })
  //     // .nullable()
  //     // .required("Client is required"),
  
  //     cc_trans_bill_to_client_id: Yup.object().when(
  //       'billOption' , {
  //         is: 1,
            
  //       then:
      
  //       Yup
  //       .object()
        
  //       .required("Client is required"),
  //      otherwise: Yup.object().required('test').nullable(),
  //     }),
  
      // cc_trans_bill_to_user_id: Yup
      // .object()
      // .shape({
      //   user_id: Yup.string().required("Name is required"),
      // })
      // .nullable()
      // .required("Name is required"),
  
      // projects: Yup
      // .object()
      // .shape({
      //   project_name: Yup.string().required("project is required"),
      //   project_id: Yup.string().required("project is required"),
      // })
      // .nullable()
      // .required("project is required"),
  
      // task: Yup
      // .object()
      // .shape({
      //   task_name: Yup.string().required("Task is required"),
      //   ts_task_id: Yup.string().required("Task is required"),
      // })
      // .nullable()
      // .required("Task is required"),
  
      // subtask: Yup
      // .object()
      // .shape({
      //   task_name: Yup.string().required("Sub Task is required"),
      //   ts_task_id: Yup.string().required("Sub Task is required"),
      // })
      // .nullable()
      // .required("Sub Task is required"), 
  
  //   cc_trans_dt: Yup.string().required("required"),//date().default(() => new Date()),
  //   credit_option: Yup.string().required(),
  //   cc_trans_amt: Yup.string().required("required"),
  //   cc_trans_memo: Yup.string(),
  //   cc_trans_ticket_number: Yup.string().matches()
  
  
  // });

  const current = new Date();
  const Todaysdate = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`;
  const [currentDate, setDate] = useState(Todaysdate);
  const [creditOption, setCreditOption] = useState("false");
  const radioOptionChange = (e) => {
    const value = e.target.value;
    setCreditOption(value)
  }
  const {
    handleSubmit,
    register,
    control,
    reset,
    onChange,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });
  const onChangeticketNumber = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setTicketNumber(value);
    // inputField.cc_trans_ticket_number = value;
  };
  const onSubmit = (data, e) => {
    console.log(data);
    e.target.reset();
    // window.location.reload();
    // reset({
    //   cc_id:'',
    //   cc_trans_amt:'',
    //   userId:userDefaultValue,
    //   cc_trans_dt:Todaysdate
    // });
  }
  const selectBill = (e) => {
    if (e) {
      selectBillTo(e.key);
    }else {
      selectBillTo(undefined)
    }
    }

    const selectProject = (e) => {
      if (e) {
        const param = e.project_id;
        setProject(e.project_id)
        dispatch(tasksActions.getAllById({param}));
        // if (billOption == 1) {
        //   // onChangeGetValue({ target: { value: e?.project_id, name: 'projects' } })
        // }
      }else {
        tasks=[];
        setProject(undefined)
      }
    };
    const selectTasks = (e) =>{
      if (e) {
        const param = e.ts_task_id;
        setTask(e.ts_task_id)
        dispatch(subtasksActions.getAllById({param}));
        
      }else {
        subtasks=[];
        setTask(undefined)
      }
    };
    const selectSubTasks = (e) =>{
      if (e) {
        setSubTaskId(e.ts_task_id);
       
      }
    };
  // useEffect(() => {
  //  console.log("test")
  // },[cc_trans_bill_to])
  return (
    <div className="App">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="field-group">
          <div className="field-element"></div>
          <div className="field-element flex">
            <div className="field">
              <input type="radio" id="charge" value="false" checked={creditOption == "false"}  {...register("credit_option")} name="credit_option" onChange={radioOptionChange} />
              <label htmlFor="charge">CHARGE </label> </div>
            <div className="field credit">
              <input type="radio" id="credit" name="credit_option" value="true" checked={creditOption == "true"} {...register("credit_option")} onChange={radioOptionChange} />
              <label htmlFor="credit">CREDIT</label></div>

          </div>
          <div className="field-element"></div>
        </div>
        <div className="field-group">
          <div className="field-element">
            <div className="field">
              <div className="label">Credit Card<span className="mandatory-field">* </span></div>

              <Controller
                name="cc_id"
                control={control}
                render={({ field }) => (
                  <Select
                  // defaultValue={options[0]}
                  {...field}
                  defaultValue={creditDefaultOption}
                    isClearable 
                    isSearchable={false}
                    className="form-custom-class"
                    classNamePrefix="form-custom-class-prefix" name="cc_id"
                    options={creditcards}

                    getOptionLabel={e => e.cc_nbr}
                    getOptionValue={e => e.cc_id}
                  />
                )}
              />
              {/* <p>{control}</p> */}
              <p>{errors.cc_id?.message || errors.cc_id?.cc_nbr.message}</p>
            </div>
          </div>
          <div className="field-element">
            <div className="field">
              <div className="label">Used By<span className="mandatory-field">* </span></div>

              <Controller
                name="userId"
                control={control}
                defaultValue={userDefaultValue}
                render={({ field }) => (
                  <Select
                    {...field}
                    isClearable // enable isClearable to demonstrate extra error handling

                    className="form-custom-class"
                    classNamePrefix="form-custom-class-prefix" name="cc_trans_user_id"
                    options={users}

                    getOptionLabel={e => e.user_id}
                    getOptionValue={e => e.user_id}
                  />
                )}
              />

              <p>{errors.userId?.message || errors.userId?.user_id.message}</p>
            </div>
          </div>


          <div className="field-element">
            <div className="field">
              <label htmlFor="cc_trans_amt" className="label">Transaction Date<span className="mandatory-field">* </span></label>

              <input name="cc_trans_dt" type="date"  {...register('cc_trans_dt')} max={Todaysdate} />
              <p>{errors.cc_trans_dt?.message}</p>
            </div>
          </div>
        </div>
        <div className="field-group second-section" key="payee">
          <div className="field-element">
            <div className="field">
              <label htmlFor="cc_trans_vendor_id" className="label">Vendor<span className="mandatory-field">* </span></label>
              <Controller
                name="cc_trans_vendor_id"
                control={control}

                render={({ field }) => (
                  <Select options={payee}
                    {...field}
                    // ref={vendorRef}
                    isClearable
                    getOptionLabel={e => e.cc_trans_vendor_name}
                    getOptionValue={e => e.cc_trans_vendor_id}
                    className="form-custom-class"
                    classNamePrefix="form-custom-class-prefix" name="cc_trans_vendor_id"
                  />
                )} />

              <p>{errors.cc_trans_vendor_id?.message || errors.cc_trans_vendor_id?.cc_trans_vendor_id.message}</p>

            </div>

          </div>
          <div className="field-element">
            <div className="field">
              <label htmlFor="cc_trans_amt" className="label">Amount<span className="mandatory-field">* </span></label>
              <Controller
                name="cc_trans_amt"
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    id="input-example"
                    name="cc_trans_amt"
                    decimalsLimit={2}
                    prefix="$ "
                    allowDecimals
                    placeholder="$ 0.00"
                    allowNegativeValue={false}
                    {...register('cc_trans_amt')}
                    // value={amountValue}
                    // ref={amountRef}
                    key='amount_key'

                  // onValueChange={(value, name) => onChangeGetValue({ target: { value: value, name: 'cc_trans_amt' } })}
                  />
                )} />
              {/* <CurrencyInput  placeholder="$ 0.00"   id="input-example" name="cc_trans_amt"  type="text" /> */}
              <p>{errors.cc_trans_amt?.message}</p>
            </div>
          </div>
          <div className="field-element">
                        <label htmlFor="cc_trans_memo" className="label">Description</label>
                        <div className="field">
                          <input name="cc_trans_memo" id="memo" type="text"  {...register('cc_trans_memo')}/>
                          <p>{errors.cc_trans_memo?.message}</p>
                        </div>
                      </div>

                      <div className="field-element">
                        <label htmlFor="cc_trans_ticket_number" className="label">Ticket Number</label>
                        <div className="field">
                          <input name="cc_trans_ticket_number" id="ticketNumber" {...register('cc_trans_ticket_number')} value={ticketNumber} type="text" onChange={onChangeticketNumber} />
                          <p>{errors.cc_trans_ticket_number?.message}</p>
                        </div>
                      </div>
        </div>

        {/* {3RD ROW} */}
        <div className="field-group third-section">
                      <div className="field-element">
                        <div className="field">
                          <label htmlFor="memo" className="label">Bill To<span className="mandatory-field">* </span></label>
                          <Controller
                name="cc_trans_bill_to"
                control={control}
                render={({ field: {onChange}}) => (
                          <Select
                          // {...field}
                           options={billTo}
                            // onChange={selectBill}
                            onChange={(e) => {
                              onChange(e)
                              selectBill(e) 
                            }}
                            isClearable                         
                            // getOptionLabel={e => e.label}
                            //   getOptionValue={e => e.value}
                            className="form-custom-class"
                            classNamePrefix="form-custom-class-prefix"
                            name="cc_trans_bill_to" 
                            // required
                            // ref={billToRef}
                            />
                )}
                          />
                           <p>{errors.cc_trans_bill_to?.message || errors.cc_trans_bill_to?.value.message}</p>
                        </div></div>
                      {billOption == 1 ? (
                        <div className="field-element" key="client">
                          <div className="field">
                            <label htmlFor="cc_trans_bill_to_client_id" className="label">Client<span className="mandatory-field">* </span></label>
                            <Controller
                name="cc_trans_bill_to_client_id"
                control={control}
                render={({ field:{onChange} }) => (
                            <Select options={clients}
                            // {...field}
                            onChange={(e) => {
                              onChange(e)
                              selectClient(e) 
                            }}
                              isClearable
                              getOptionLabel={e => e.client_name}
                              getOptionValue={e => e.client_id}
                              // onChange={(e) => selectClient(e)}
                              className="form-custom-class"
                              classNamePrefix="form-custom-class-prefix"
                              name="cc_trans_bill_to_client_id"
                              // ref={clientRef}
                              // required

                            />
                            )}
                          />
                           <p>{errors.cc_trans_bill_to_client_id?.message || errors.cc_trans_bill_to_client_id?.client_id.message}</p>

                          </div>
                        </div>) :
                        
                          billOption == 3 ?
                        (

                          <div className="field-element" key="personal">
                            <div className="field">
                              <label htmlFor="cc_trans_bill_to_user_id" className="label">Name<span className="mandatory-field">* </span></label>
                              <Controller
                name="cc_trans_bill_to_user_id"
                control={control}
                render={({ field:{onChange} }) => (
                              <Select options={users}
                              // {...field}
                              onChange={(e) => {
                                onChange(e)
                                selectPersonal(e) 
                              }}
                                isClearable
                                getOptionLabel={e => e.user_id}
                                getOptionValue={e => e.user_id}
                                // onChange={(e) => selectPersonal(e)}
                                className="form-custom-class"
                                classNamePrefix="form-custom-class-prefix"
                                name="cc_trans_bill_to_user_id"
                                // ref={personRef}
                                // required

                                />
                                )}
                              />
                            </div>
                          </div>) : (<div>

                          </div>)}
                      {/* client options*/}
                      {(billOption == 1 && projects.length > 0 && clientOption && (clientExpTaskLevel != null && clientExpTaskLevel != 0)) ? (

                        <div className="field-element" key="Projects">
                          <div className="field">
                            <label htmlFor="memo" className="label">Project<span className="mandatory-field">* </span></label>
                            <label htmlFor="cc_trans_bill_to_user_id" className="label">Name<span className="mandatory-field">* </span></label>
                              <Controller
                name="projects"
                control={control}
                render={({ field }) => (
                            <Select options={projects}
                            {...field}
                              isClearable
                              getOptionLabel={e => e.project_name}
                                getOptionValue={e => e.project_id}
                              onChange={(e) => selectProject(e)}
                              className="form-custom-class"
                              classNamePrefix="form-custom-class-prefix"
                              name="projects"
                              // ref={projectsRef}
                              // required
                              />
                              )}
                            />
                          </div>
                        </div>) : (<div className="field-element"></div>)}

                      {/* client options*/}
                    </div>

                    {/* {new dropdown} */}
                   
                    <div className="field-group">
                    {(billOption == 1 && tasks.length > 0 && projectOption && clientOption && projects.length > 0) ? (
                      <div className="field-element">
                        <div className="field">
                          <label htmlFor="task" className="label">Task<span className="mandatory-field">* </span></label>
                          <Controller
                name="task"
                control={control}
                render={({ field }) => (
                          <Select options={tasks}
                          {...field}
                            onChange={(e) => selectTasks(e)}
                            isClearable
                            getOptionLabel={e => e.task_name}
                            getOptionValue={e => e.ts_task_id}
                            className="form-custom-class"
                            classNamePrefix="form-custom-class-prefix"
                            name="task" 
                            // ref={taskRef}
                            // required

                            />
                            )}
                          />
                        </div></div>) : <div></div>}

                        {( billOption == 1 && subtasks.length > 0 && taskOption && projectOption && clientOption && projects.length > 0)  ? (
                        <div className="field-element">
                        <div className="field">
                          <label htmlFor="subtask" className="label">Sub Task<span className="mandatory-field">* </span></label>
                          <Controller
                name="subtask"
                control={control}
                render={({ field }) => (
                          <Select options={subtasks}
                          {...field}
                            onChange={(e) => selectSubTasks(e)}
                            isClearable
                            getOptionLabel={e => e.task_name}
                            getOptionValue={e => e.ts_task_id}
                            className="form-custom-class"
                            classNamePrefix="form-custom-class-prefix"
                            name="subtask" 
                            // ref={subtaskRef}
                            // required

                            />
                            )}
                          />
                        </div></div>):(
                        <div className="field-element"></div>)}
                        
                        <div className="field-element"></div>
                        </div>
{/* {tasks end} */}

                    {/* <div>conditional</div> */}
                    {/* {3rd row ends here} */}
        <input type="submit" />
      </form>
    </div>
  );
}

