import Button from 'react-bootstrap/Button';
import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import {FilterDashboard} from '../TekCCReceipts/FilterDashboard/FilterDashboard';
import {ReimbursementFilterDashboard} from '../ExpenseReimbursement/ExpenseReimbursementFilterDashboard/ExpenseReimbursementFilterDashboard';
import {MileageReimbursementFilterDashboard} from '../MileageReimbursement/MileageReimbursementFilterDashboard/MileageReimbursementFilterDashboard';
import { MissingReceiptsFilterDashboard } from '../MissingReceipts/MissingReceiptsFilterDashboard/MissingReceiptsFilterDashboard';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

export function FilterModal(props) {
  

const overlayRef = useRef();
  
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      ref={overlayRef}
      className= {props.className + " filter-modal"}
    >
      {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header> */}
      <h4>Filter Records</h4>
      <Modal.Body>
        {/* <h4>Centered Modal</h4> */}
     
       
        {props.reimbursement ? 
        <ReimbursementFilterDashboard sdata={props}></ReimbursementFilterDashboard> : 
        props.mileagereimbursement ? 
        <MileageReimbursementFilterDashboard sdata={props}></MileageReimbursementFilterDashboard> : 
         props.missingReceipts ?  <MissingReceiptsFilterDashboard sdata={props} className="missing-receipts"></MissingReceiptsFilterDashboard> :
        <FilterDashboard sdata={props}></FilterDashboard> }
        {/* <div className='flex'>
        <OverlayTrigger
                                key='bottom-apply'
                                placement='bottom'
                                overlay={
                                    <Tooltip id='tooltip-bottom'>
                                        Apply Filters
                                    </Tooltip>
                                }
                               >
                                 <Button onClick={props.onHide}> <img src={'/assets/icons/checkmark.svg'} /></Button>
                             
                            </OverlayTrigger>
                            <OverlayTrigger
                                key='bottom-cancel'
                                placement='bottom'
                                overlay={
                                    <Tooltip id='tooltip-bottom'>
                                        Cancel
                                    </Tooltip>
                                }
                               >
                         <Button onClick={props.onHide} className="close-button"> <img src={'/assets/icons/close.svg'} /></Button>
                             
                            </OverlayTrigger>
       
        
        </div> */}
        {/* <p>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </p> */}
      {/* <Modal.Header closeButton> </Modal.Header> */}
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );

    }