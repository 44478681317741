import React, { useEffect, useState } from 'react';
import './Dashboard.scss';
import { FormModal } from '../../_components/FormModal';
import { FilterModal } from '../../_components/FilterModal';
import { ExportModal } from '../../_components/ExportModal';
import { DeleteModal } from '../../_components/DeleteModal';
import { dashboardActions } from "../../_store";
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Spinner from 'react-bootstrap/Spinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
import { Navigate } from 'react-router-dom';
import Pagination from '../../_components/PaginationRange';
import { formatValue } from 'react-currency-input-field';

export { Dashboard }
function Dashboard() {
    const { user: authUser } = useSelector(x => x.auth);
    if (!authUser) {
        return <Navigate to="/login" state={{ from: history.location }} />
    }
    const dispatch = useDispatch();
    const [modalShow, setModalShow] = React.useState(false);
    const [modalData, setModalData] = React.useState();
    const [modalFilterShow, setFilterModalShow] = React.useState(false);
    const [modalExportShow, setModalExportShow] = React.useState(false);
    const [modalDeleteShow, setModalDeleteShow] = React.useState(false);
    const [modalDeleteData, setModalDeleteData] = React.useState();
    const ccObj = JSON.parse(localStorage.getItem('ccValue'));
    const CreditCard = ccObj.cc_id ? ccObj.cc_id : '';
    const [currentPage, setCurrentPage] = useState(1);
    const [totalAmount, setTotalAmount] = useState();
    const [recordsPerPage, setRecordsPerPage] = useState(25);
    const [selectedCount, setSelectedCount] = useState(0);

    let dashboard = useSelector(x => x.dashboard);
    let dashboardList = [];
    dashboardList = dashboard.dashboard.getReceipts;
    const dashboardArgs = dashboard.dashboardArgs;
    const apipagination = dashboard.dashboard.pagination;

    let Filterobj = {
        cc_ids: CreditCard,
        cc_trans_user_id: '',
        // cc_trans_uploaded_by: authUser.user.user_id,
        cc_trans_uploaded_by: '',
        cc_trans_from_dt: null,
        cc_trans_to_dt: null,
        cc_trans_uploaded_from_dt: null,
        cc_trans_uploaded_to_dt: null,
        cc_trans_vendor_id: '',
        cc_trans_amt: '',
        cc_trans_memo: '',
        cc_trans_ticket_number: '',
        cc_trans_bill_to: '',
        cc_trans_bill_to_client_id: '',
        cc_trans_bill_to_project_id: '',
        cc_trans_purchase_type_id: '',
        cc_trans_bill_to_user_id: '',
        cc_trans_bill_to_task_id: '',
        cc_trans_bill_to_sub_task_id: '',
        sortColumn: 'cc_trans_dt',
        sortOrder: 'DESC',
        pageNumber: currentPage,
        pageSize: recordsPerPage,
        isCharge: true,
        reconciled:0
    }

    let filterObjectSearchValues = {};

    useEffect(() => {
        if (localStorage.getItem('filterObjValues') != null) {
            let localStorageFilerObj = JSON.parse(localStorage.getItem('filterObjValues'))
            dispatch(dashboardActions.getAll(localStorageFilerObj));
            localStorageFilerObj.pageNumber = '';
            localStorageFilerObj.pageSize = '';
        } else {
            dispatch(dashboardActions.getAll(Filterobj));
            localStorage.setItem('filterObjValues', JSON.stringify(Filterobj));
            Filterobj.pageNumber = '';
            Filterobj.pageSize = '';
        }
    }, [])

    useEffect(() => {
        var sum = 0;
        for (var i = 0; i < dashboardList?.length; i++) {
            if (dashboardList[i].cc_trans_amt != null && dashboardList[i].cc_trans_amt != '') {
                sum += parseFloat(dashboardList[i].cc_trans_amt?.replace(/,/g, ""));
            }
        }
        setTotalAmount(sum.toString())
        // Filter count Display
    const storedFilters = localStorage.getItem('filterObjValues');
    const localStorageFilerObj = JSON.parse(storedFilters);
    const { pageNumber, pageSize, sortColumn, sortOrder, cc_trans_memo, ...localStorageFilterdObj } = localStorageFilerObj;


    console.log(localStorageFilterdObj);
    const countPropertiesWithValue = (obj) => {
        let count = 0;
        let dateFieldCounted = false;
        let uploadDateFieldCounted = false;
      
        for (const [key, value] of Object.entries(obj)) {
          if ((key === 'cc_trans_from_dt' || key === 'cc_trans_to_dt')) {
            if (!dateFieldCounted && (obj['cc_trans_from_dt'] || obj['cc_trans_to_dt'])) {
              count += 1;
              dateFieldCounted = true;
            }
          } else if ((key === 'cc_trans_uploaded_from_dt' || key === 'cc_trans_uploaded_to_dt')) {
            if (!uploadDateFieldCounted && (obj['cc_trans_uploaded_from_dt'] || obj['cc_trans_uploaded_to_dt'])) {
              count += 1;
              uploadDateFieldCounted = true;
            } 
          } else if(key === 'reconciled'){
            console.log(`Reconciled value: ${value}`);
            if (value === true) {
                count += 1;
              }
        } else if (value !== null && value !== "") {
            count += 1;
          }
        }
        return count;
      };

    const count = countPropertiesWithValue(localStorageFilterdObj);
    console.log('Filtered object:', localStorageFilterdObj);
    console.log('Selected count:', count);
    setSelectedCount(count);
    }, [dashboardList]);


    let formattedValue1 = 0;
    if (totalAmount) {
        formattedValue1 = formatValue({
            value: totalAmount,
            groupSeparator: ',',
            decimalSeparator: '.',
            decimalScale: 2,
        });
    }

    useEffect(() => {
        if (localStorage.getItem('filterObjValues') != null) {
            filterObjectSearchValues = JSON.parse(localStorage.getItem('filterObjValues'));
            filterObjectSearchValues.pageNumber = currentPage;
            filterObjectSearchValues.pageSize = recordsPerPage;
            dispatch(dashboardActions.getAll(filterObjectSearchValues));
            localStorage.setItem('filterObjValues', JSON.stringify(filterObjectSearchValues));
        } else {
            dispatch(dashboardActions.getAll(Filterobj));
        }
    }, [currentPage, recordsPerPage]);


    const columns = [
        { dataField: 'cc_nbr', text: 'CREDIT CARD', sort: true, order: 'ASC' },
        { dataField: 'cc_trans_user_id', text: 'USED BY', sort: true, order: 'default' },
        { dataField: 'cc_trans_dt', text: 'DATE', sort: true, order: 'default' },
        { dataField: 'cc_trans_vendor_name', text: 'VENDOR', sort: true, order: 'default' },
        { dataField: 'cc_trans_amt', text: 'AMOUNT', sort: true, order: 'default' },
        { dataField: 'cc_trans_memo', text: 'DESCRIPTION', sort: true, order: 'default' },
        { dataField: 'cc_trans_ticket_number', text: 'TICKET NUMBER', sort: true, order: 'default' },
        { dataField: 'cc_trans_bill_to', text: 'BILLED TO', sort: true, order: 'default' },
        { dataField: 'cc_trans_uploaded_by', text: 'UPLOADED BY', sort: true, order: 'default' },
        { dataField: 'cc_trans_uploaded_dt', text: 'UPLOADED DATE', sort: true, order: 'default' },
    ];


    function sortClicked(columnObj) {
        if (columnObj.dataField == dashboardArgs?.sortColumn) {
            const orderAPI = dashboardArgs.sortOrder === 'DESC'
                ? 'ASC'
                : 'DESC';
            if (localStorage.getItem('filterObjValues')) {
                filterObjectSearchValues = JSON.parse(localStorage.getItem('filterObjValues'));
                filterObjectSearchValues.sortColumn = columnObj.dataField;
                filterObjectSearchValues.sortOrder = orderAPI;
                localStorage.setItem('filterObjValues', JSON.stringify(filterObjectSearchValues));
            }
        } else {
            if (columnObj.sort === false) {
                return;
            }
            for (let i = 0; i < columns.length; i++) {
                if (columns[i].dataField !== columnObj.dataField) {
                    columns[i].order = 'default';
                }
                else {
                    columns[i].order =
                        columns[i].order === 'default' ||
                            columns[i].order === 'DESC'
                            ? 'ASC'
                            : 'DESC';

                    if (localStorage.getItem('filterObjValues')) {
                        filterObjectSearchValues = JSON.parse(localStorage.getItem('filterObjValues'));
                        filterObjectSearchValues.sortColumn = columnObj.dataField;
                        filterObjectSearchValues.sortOrder = columns[i].order;
                        localStorage.setItem('filterObjValues', JSON.stringify(filterObjectSearchValues));
                    }
                }
            }
        }
        const copyFilterobj = Object.assign({}, filterObjectSearchValues);
        dispatch(dashboardActions.getAll(copyFilterobj));
        copyFilterobj.pageNumber = '';
        copyFilterobj.pageSize = '';
    }

    // var nPages;
    // if (dashboardList?.length > 0) {
    //     const indexOfLastRecord = currentPage * recordsPerPage;
    //     const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    //     const currentRecords = dashboardList.slice(indexOfFirstRecord, indexOfLastRecord);
    //     nPages = Math.ceil(apipagination.countRows / recordsPerPage)
    // }

    const [fullscreen, setFullscreen] = React.useState(true);
    function openEditOverlay(rowData) {
        setModalShow(true);
        setModalData(rowData)
        setFullscreen(true);
    }

    function openDeleteOverlay(rowData) {
        setModalDeleteData(rowData.cc_trans_id)
        setModalDeleteShow(true);
    }

    return (
        <>
            <FormModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                modaldata={modalData}
                fullscreen={fullscreen}
            />
            <FilterModal
                show={modalFilterShow}
                onHide={() => setFilterModalShow(false)}
                filterobj={Filterobj}
                setcurrentpage={()=>setCurrentPage}
                setrecordsperpage={()=>setRecordsPerPage}
            />
            <ExportModal
                show={modalExportShow}
                onHide={() => setModalExportShow(false)}
            />
            <DeleteModal
                show={modalDeleteShow}
                modaldata={modalDeleteData}
                onHide={() => setModalDeleteShow(false)}
            />
            <div className="dashboard dashboards">
                {!dashboardList &&
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>}

                <main>
                    <div className="container">
                        <div className='filter-container'>

                            <div className='dash-icon'>
                                <div></div>
                                <h6 className='dash-title dash-title-dashboard'>TEK CC RECEIPT DASHBOARD</h6>
                                <div className='dash-trigger-icons'>
                                    <OverlayTrigger
                                        key='tooltip-exports'
                                        placement='bottom'
                                        overlay={
                                            <Tooltip id='tooltip-export'>
                                                Export Files
                                            </Tooltip>
                                        }
                                    >
                                    <a onClick={() => setModalExportShow(true)}><img src={'/assets/images/export.jpg'} /></a>

                                    </OverlayTrigger>

                                    <OverlayTrigger
                                        key='upload'
                                        placement='bottom'
                                        overlay={
                                            <Tooltip id='tooltip-upload'>
                                                Tek CC Receipt
                                            </Tooltip>
                                        }
                                    >
                                        <a href="/UploadReceipt"><img src={'/assets/images/upload.png'} /></a>
                                    </OverlayTrigger>

                                    <OverlayTrigger
                                        key='filter'
                                        placement='bottom'
                                        overlay={
                                            <Tooltip id='tooltip-filter'>
                                                Filter
                                            </Tooltip>
                                        }
                                    >
                                        <a onClick={() => setFilterModalShow(true)}> <img src={'/assets/icons/filter.svg'} />{selectedCount > 0 && <span className='filter-count'>{ `${selectedCount}`}</span> }</a>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div className="login-form ">
                                <div className="table-container table-responsive">                      
                                    <table className="credit-table-container table-layout">
                                        <thead>
                                            <tr>
                                                {columns.map((columnObj, index) => {
                                                    return (
                                                        <th key={index} onClick={() => sortClicked(columnObj)}>
                                                            {dashboardArgs.sortColumn == columnObj.dataField ?
                                                                (<span >{columnObj.text}
                                                                    <span className={dashboardArgs.sortOrder == 'DESC' ? 'sort-desc-icon' : dashboardArgs.sortOrder == 'ASC' ? 'sort-asc-icon' : 'sort-default-icon'}></span>
                                                                </span>
                                                                ) : (
                                                                    <span >{columnObj.text}
                                                                        <span className='sort-default-icon'></span>
                                                                    </span>)}
                                                        </th>
                                                    )
                                                })}
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                    </table>
                                    <div className="scroll-bar">
                                        <table className="credit-list-table table-layout">
                                            <tbody>
                                                {dashboardList?.map((listValue, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{listValue.cc_nbr}</td>
                                                            <td>{listValue.cc_trans_user_id}</td>
                                                            <td>{listValue.cc_trans_dt ? moment(listValue.cc_trans_dt).format('MM/DD/YYYY') : ''}</td>
                                                            <td>{listValue.cc_trans_vendor_name}</td>
                                                            <td className={parseFloat(listValue.cc_trans_amt, 10) > 0 ? 'positive' : 'negative'}>{parseFloat(listValue.cc_trans_amt, 10) > 0 ? '$' + listValue.cc_trans_amt : (listValue.cc_trans_amt == '' || listValue.cc_trans_amt == null) ? '' : '(' + '$' + listValue.cc_trans_amt?.slice(1) + ')'}</td>
                                                            <td>{listValue.cc_trans_memo}</td>
                                                            <td className=''>{listValue.cc_trans_ticket_number}</td>
                                                            <td>
                                                                <OverlayTrigger
                                                                    key='bottom-details'
                                                                    placement='left'

                                                                    overlay={
                                                                        <Tooltip id='tooltip-bottom'>
                                                                            {listValue.cc_trans_bill_to_details}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span>{listValue.cc_trans_bill_to_details}</span>
                                                                </OverlayTrigger>
                                                            </td>
                                                            <td>{listValue.cc_trans_uploaded_by}</td>
                                                            <td>{listValue.cc_trans_uploaded_dt ? moment(listValue.cc_trans_uploaded_dt).format('MM/DD/YYYY') : ''}</td>
                                                            <td>  <OverlayTrigger
                                                                        key='bottom-view'
                                                                        placement='bottom'

                                                                        overlay={
                                                                            <Tooltip id='tooltip-bottom'>
                                                                                View Receipt
                                                                            </Tooltip>
                                                                        }>
                                                                           <a href={listValue.file_path + '?' + new Date().getTime()} target="_blank"><img src={'/assets/icons/view-small.svg'} /></a>
                                                                    </OverlayTrigger></td>
                                                            {listValue.cc_trans_status == 'S' ?
                                                                <td>
                                                                    <OverlayTrigger
                                                                        key='bottom-edit'
                                                                        placement='bottom'

                                                                        overlay={
                                                                            <Tooltip id='tooltip-bottom'>
                                                                                Edit
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <img src={'/assets/icons/edit-small.svg'} onClick={() => openEditOverlay(listValue)}></img>
                                                                    </OverlayTrigger>

                                                                    <OverlayTrigger
                                                                        key='bottom-delete'
                                                                        placement='bottom'

                                                                        overlay={
                                                                            <Tooltip id='tooltip-bottom'>
                                                                                Delete
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <img src={'/assets/icons/delete.svg'} onClick={() => openDeleteOverlay(listValue)}></img>
                                                                    </OverlayTrigger>
                                                                </td>
                                                                : <td>{listValue.cc_trans_status == 'U' ? (<span className="badge badge-success">ACCEPTED</span>) : listValue.cc_trans_status == 'C' ? (<span className="badge badge-orange">CLEARED</span>) : listValue.cc_trans_status == 'R' ? (<span className="badge badge-purple">RECONCILED</span>) : 
                                                                listValue.cc_trans_bill_status == 'I' ? (<span className="badge badge-blue">INV# {listValue.client_inv_id}</span>) : (<span></span>)}</td>}
                                                        
                                                        </tr>
                                                    )
                                                })}

                                            </tbody>
                                        </table>
                                        {dashboardList?.length <= 0 &&
                                            <div className='no-records'>No Data found</div>
                                        }

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </main>
            </div>
           
            {dashboardList?.length > 0 &&
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={apipagination.countRows}
                    pageSize={recordsPerPage}
                    pageRange={apipagination.pageRange}
                    setCurrentPage={setCurrentPage}
                    setRecordsPerPage={setRecordsPerPage}
                    onPageChange={page => setCurrentPage(page)}
                    totalAmount={formattedValue1}
                />
            }
        </>
    );
}