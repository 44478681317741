import React, { useEffect, useState } from 'react';
import './MileageReimbursementDashboard.scss';
import { FormModal } from '../../_components/FormModal';
import { FilterModal } from '../../_components/FilterModal';
import { MileageReimbursementExportModal } from '../../_components/MileageReimbursementExportModal';
import { MileageReimbursementDeleteModal } from '../../_components/MileageReimbursementDeleteModal';
import { mileageReimbursementDashboardActions } from "../../_store";
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Spinner from 'react-bootstrap/Spinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
import { Navigate } from 'react-router-dom';
import Pagination from '../../_components/PaginationRange';
import { formatValue } from 'react-currency-input-field';
import { mileageRatesActions } from '../../_store';

export { MileageReimbursementDashboard }
function MileageReimbursementDashboard() {
    const { user: authUser } = useSelector(x => x.auth);
    if (!authUser) {
        return <Navigate to="/login" state={{ from: history.location }} />
    }
    const dispatch = useDispatch();
    const [modalShow, setModalShow] = React.useState(false);
    const [modalData, setModalData] = React.useState();
    const [modalFilterShow, setFilterModalShow] = React.useState(false);
    const [modalExportShow, setModalExportShow] = React.useState(false);
    const [modalDeleteShow, setModalDeleteShow] = React.useState(false);
    const [modalDeleteData, setModalDeleteData] = React.useState();
    const ccObj = JSON.parse(localStorage.getItem('ccValue'));
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(25);
    const [totalAmount, setTotalAmount] = useState();
    const { mileageRates } = useSelector(x => x.mileageRates);
    const [selectedCount, setSelectedCount] = useState(0);
    let dashboard = useSelector(x => x.dashboard);
    let dashboardList = [];
    dashboardList = dashboard.dashboard.mileageReimbursement;
    const dashboardArgs = dashboard.dashboardArgs;
    const apipagination = dashboard.dashboard.pagination;
    
    let Filterobj = {
        expense_mileage_from_dt: "",
        expense_mileage_to_dt: "",
        expense_mileage_from: "",
        expense_mileage_to: "",
        expense_mileage_odometer_start: "",
        expense_mileage_odometer_end: "",
        expense_mileage_user_id: authUser.user.user_id,
        expense_mileage_bill_to: "",
        expense_mileage_bill_to_client_id: "",
        sortColumn: 'expense_mileage_dt',
        sortOrder: 'DESC',
        pageNumber: currentPage,
        pageSize: recordsPerPage,
        exportExcel: ""
    }

    let filterObjectSearchValues = {};

    useEffect(() => {
        if (localStorage.getItem('mileageFilterObjValues') != null) {
            let localStorageFilerObj = JSON.parse(localStorage.getItem('mileageFilterObjValues'))
            dispatch(mileageReimbursementDashboardActions.getAll(localStorageFilerObj));
            localStorageFilerObj.pageNumber = '';
            localStorageFilerObj.pageSize = '';
        } else {
            dispatch(mileageReimbursementDashboardActions.getAll(Filterobj));
            localStorage.setItem('mileageFilterObjValues', JSON.stringify(Filterobj));
            Filterobj.pageNumber = '';
            Filterobj.pageSize = '';
        }
        dispatch(mileageRatesActions.getAll());
    }, [])

    useEffect(() => {
        // var sum = 0;
        // for (var i = 0; i < dashboardList?.length; i++) {
        //     let value = dashboardList[i].expense_mileage_odometer_end - dashboardList[i].expense_mileage_odometer_start;
        //     for (let i = 0; i < mileageRates.length; i++) {
        //         const startDate = new Date(moment(mileageRates[i].mileage_rate_start_dt).format('YYYY-MM-DD'));
        //         const endDate = new Date(moment(mileageRates[i].mileage_rate_end_dt).format('YYYY-MM-DD'));
        //         // const dateToCheck = new Date(dashboardList[i]?.expense_mileage_dt);
        //         const dateToCheck = dashboardList[i]?.expense_mileage_dt ? new Date(dashboardList[i].expense_mileage_dt) : null;
        //         if (dateToCheck >= startDate && dateToCheck <= endDate) {
        //             const getAmtValue = (value * mileageRates[i].mileage_rate);
        //             if (getAmtValue !== null && getAmtValue !== "") {
        //                 sum += parseFloat(getAmtValue);
        //             }
        //         }
        //     }
        // }
        // setTotalAmount(sum.toString())
         // Filter count Display
         var sum = 0;
         for (var i = 0; i < dashboardList?.length; i++) {
             if (dashboardList[i].expense_mileage_amount != null && dashboardList[i].expense_mileage_amount != '') {
                 sum += parseFloat(dashboardList[i].expense_mileage_amount);
             }
         }
         
         setTotalAmount(sum.toString())
        //  setTotalAmount(dashboardList ? dashboardList[0].total_amt.replace(/,/g, ''): 0);
    
    const storedFilters = localStorage.getItem('mileageFilterObjValues');
    const localStorageFilerObj = JSON.parse(storedFilters);
    const { pageNumber, pageSize, sortColumn, sortOrder, ...localStorageFilterdObj } = localStorageFilerObj;


    console.log(localStorageFilterdObj);
    const countPropertiesWithValue = (obj) => {
        let count = 0;
        let dateFieldCounted = false;
      
        for (const [key, value] of Object.entries(obj)) {
          if ((key === 'expense_mileage_from_dt' || key === 'expense_mileage_to_dt')) {
            if (!dateFieldCounted && (obj['expense_mileage_from_dt'] || obj['expense_mileage_to_dt'])) {
              count += 1;
              dateFieldCounted = true;
            }
          }  else if (value !== null && value !== "") {
            count += 1;
          }
        }
        return count;
      };

    const count = countPropertiesWithValue(localStorageFilterdObj);
    console.log('Filtered object:', localStorageFilterdObj);
    console.log('Selected count:', count);
    setSelectedCount(count);
    }, [dashboardList]);

    let formattedValue1 = 0;
    if (totalAmount) {
        formattedValue1 = formatValue({
            value: totalAmount,
            groupSeparator: ',',
            decimalSeparator: '.',
            decimalScale: 2,
        });
    }

    useEffect(() => {
        if (localStorage.getItem('mileageFilterObjValues') != null) {
            filterObjectSearchValues = JSON.parse(localStorage.getItem('mileageFilterObjValues'));
            filterObjectSearchValues.pageNumber = currentPage;
            filterObjectSearchValues.pageSize = recordsPerPage;
            dispatch(mileageReimbursementDashboardActions.getAll(filterObjectSearchValues));
            localStorage.setItem('mileageFilterObjValues', JSON.stringify(filterObjectSearchValues));
        } 
        else {
            dispatch(mileageReimbursementDashboardActions.getAll(Filterobj));
        }
    }, [currentPage, recordsPerPage]);


    const columns = [

        { dataField: 'expense_mileage_user_id', text: 'UPLOADED BY', sort: true, order: 'default' },
        { dataField: 'expense_mileage_dt', text: 'DATE', sort: true, order: 'default' },
        { dataField: 'expense_mileage_from', text: 'From', sort: true, order: 'default' },
        { dataField: 'expense_mileage_to', text: 'To', sort: true, order: 'default' },
        // { dataField: 'expense_mileage_bill_to', text: 'BILLED TO', sort: true, order: 'default'},
        { dataField: 'expense_mileage_odometer_start', text: 'Odometer Start', sort: true, order: 'default' },
        { dataField: 'expense_mileage_odometer_end', text: 'Odometer End', sort: true, order: 'default' },
        { dataField: 'expense_mileage_no_of_miles', text: 'No Of Miles', sort: true, order: 'default' },
        { dataField: 'expense_mileage_amount', text: 'AMOUNT', sort: true, order: 'default' },
        
    ];


    function sortClicked(columnObj) {

        if (columnObj.dataField == dashboardArgs?.sortColumn) {
            const orderAPI = dashboardArgs.sortOrder === 'DESC'
                ? 'ASC'
                : 'DESC';
            if (localStorage.getItem('mileageFilterObjValues')) {
                filterObjectSearchValues = JSON.parse(localStorage.getItem('mileageFilterObjValues'));
                filterObjectSearchValues.sortColumn = columnObj.dataField;
                filterObjectSearchValues.sortOrder = orderAPI;
                localStorage.setItem('mileageFilterObjValues', JSON.stringify(filterObjectSearchValues));
            }
           

        } else {

            if (columnObj.sort === false) {
                return;
            }
            for (let i = 0; i < columns.length; i++) {
                if (columns[i].dataField !== columnObj.dataField) {
                    columns[i].order = 'default';
                } else {
                    columns[i].order =
                        columns[i].order === 'default' ||
                            columns[i].order === 'DESC'
                            ? 'ASC'
                            : 'DESC';                    
                    if (localStorage.getItem('mileageFilterObjValues')) {
                        filterObjectSearchValues = JSON.parse(localStorage.getItem('mileageFilterObjValues'));
                        filterObjectSearchValues.sortColumn = columnObj.dataField;
                        filterObjectSearchValues.sortOrder = columns[i].order;
                        localStorage.setItem('mileageFilterObjValues', JSON.stringify(filterObjectSearchValues));

                    }
                }
            }
        }



        const copyFilterobj = Object.assign({}, filterObjectSearchValues);

        dispatch(mileageReimbursementDashboardActions.getAll(copyFilterobj));
        copyFilterobj.pageNumber = '';
        copyFilterobj.pageSize = '';
    }    
    
    var nPages;
    if (dashboardList?.length > 0) {
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        const currentRecords = dashboardList.slice(indexOfFirstRecord, indexOfLastRecord);

        nPages = Math.ceil(apipagination.countRows / recordsPerPage)

    }

    const [fullscreen, setFullscreen] = React.useState(true);

    function openEditOverlay(rowData) {
        setModalShow(true);
        setModalData(rowData)
        setFullscreen(true);
    }
    function openDeleteOverlay(rowData) {
        setModalDeleteData(rowData.expense_mileage_id)

        setModalDeleteShow(true);
    }
    // function getAmount(date, end, start) {

    //     for (let i = 0; i < mileageRates.length; i++) {
    //         const startDate = new Date(moment(mileageRates[i].mileage_rate_start_dt).format('YYYY-MM-DD'));

    //         const endDate = new Date(moment(mileageRates[i].mileage_rate_end_dt).format('YYYY-MM-DD'));
    //         const dateToCheck = new Date(date);

    //         if (dateToCheck >= startDate && dateToCheck <= endDate) {
    //             const value = ((end - start) * mileageRates[i].mileage_rate).toString();
               
    //             const formattedAmountValue = formatValue({
    //                 value: value,
    //                 groupSeparator: ',',
    //                 decimalSeparator: '.',
    //                 decimalScale: 2,
    //                 prefix: '$',
    //             });
    //             return formattedAmountValue;
    //         }
    //     }

    // }

    function getAmount(date, end, start) {
        const dateToCheck = new Date(date);
        console.log('Date to check:', dateToCheck);
    
        for (let i = 0; i < mileageRates.length; i++) {
            const startDate = new Date(mileageRates[i].mileage_rate_start_dt);
            const endDate = new Date(mileageRates[i].mileage_rate_end_dt);
    
            console.log('Checking range:', startDate, 'to', endDate);
    
          
            if (dateToCheck >= startDate && dateToCheck <= endDate) {
                const value = ((end - start) * mileageRates[i].mileage_rate).toFixed(2);
                
    
                const formattedAmountValue = formatValue({
                    value: value,
                    groupSeparator: ',',
                    decimalSeparator: '.',
                    decimalScale: 2,
                    prefix: '$',
                });
                return formattedAmountValue;
            }
        }
    
        // Optional: Return a default value or throw an error if no match is found
        console.log('No matching range found for the given date.');
        return null;
    }



    return (
        <>
            <FormModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                modaldata={modalData}
                fullscreen={fullscreen}
                mileagereimbursement={true}
            />
            <FilterModal
                show={modalFilterShow}
                onHide={() => setFilterModalShow(false)}
                filterobj={Filterobj}
                setcurrentpage={setCurrentPage}
                setrecordsperpage={setRecordsPerPage}
                mileagereimbursement={true}
            />
           
            <MileageReimbursementExportModal
                show={modalExportShow}
                onHide={() => setModalExportShow(false)}
            />
            <MileageReimbursementDeleteModal
                show={modalDeleteShow}
                modaldata={modalDeleteData}
                onHide={() => setModalDeleteShow(false)}
            />
            <div className="mileage-dashboard dashboards">
                {!dashboardList &&
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>}
                <main>
                    <div className="container">
                        <div className='filter-container'>
                            <div className='dash-icon'>
                                <div></div>
                                <h6 className='dash-title dash-title-dashboard'>MILEAGE REIMBURSEMENT DASHBOARD</h6>
                                <div className='dash-trigger-icons'>
                                    <OverlayTrigger
                                        key='tooltip-exports'
                                        placement='bottom'
                                        overlay={
                                            <Tooltip id='tooltip-export'>
                                                Export Files
                                            </Tooltip>
                                        }
                                    >
                                        <a onClick={() => setModalExportShow(true)}><img src={'/assets/images/export.jpg'} /></a>

                                    </OverlayTrigger>

                                    <OverlayTrigger
                                        key='upload'
                                        placement='bottom'
                                        overlay={
                                            <Tooltip id='tooltip-upload'>
                                                Mileage Reimbursement Form
                                            </Tooltip>
                                        }
                                    >
                                        <a href="/MileageReimbursementForm"><img src={'/assets/images/upload.png'} /></a>

                                    </OverlayTrigger>

                                    <OverlayTrigger
                                        key='filter'
                                        placement='bottom'
                                        overlay={
                                            <Tooltip id='tooltip-filter'>
                                                Filter
                                            </Tooltip>
                                        }
                                    >
                                        <a onClick={() => setFilterModalShow(true)}> <img src={'/assets/icons/filter.svg'} />{selectedCount > 0 && <span className='filter-count'>{ `${selectedCount}`}</span>}</a>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div className="login-form ">
                                <div className="table-container table-responsive">                                
                                    <table className="credit-table-container table-layout">
                                        <thead>
                                            <tr >
                                                {columns.map((columnObj, index) => {
                                                    return (

                                                        <th key={index} onClick={() => sortClicked(columnObj)}>
                                                            {dashboardArgs.sortColumn == columnObj.dataField ?
                                                                (<span >{columnObj.text}
                                                                    <span className={dashboardArgs.sortOrder == 'DESC' ? 'sort-desc-icon' : dashboardArgs.sortOrder == 'ASC' ? 'sort-asc-icon' : 'sort-default-icon'}></span>
                                                                </span>
                                                                ) : (
                                                                    <span >{columnObj.text}
                                                                        <span className='sort-default-icon'></span>
                                                                    </span>)}
                                                        </th>
                                                    )
                                                })}
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                    </table>
                                    <div className="scroll-bar">
                                        <table className="credit-list-table table-layout">
                                            <tbody>
                                                {dashboardList?.map((listValue, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{listValue.expense_mileage_user_id}</td>
                                                            <td>{listValue.expense_mileage_dt ? moment(listValue.expense_mileage_dt).format('MM/DD/YYYY') : ''}</td>
                                                            <td>{listValue.expense_mileage_from}</td>
                                                            <td>{listValue.expense_mileage_to}</td>
                                                            {/* <td>{listValue.expense_mileage_bill_to_details}
                                                                <OverlayTrigger
                                                                    key='bottom'
                                                                    placement='left'
                                                                    overlay={
                                                                        <Tooltip id='tooltip-bottom'>
                                                                            {listValue.expense_mileage_bill_to_details}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span>{listValue.reimbursement_trans_bill_to_details}</span>
                                                                </OverlayTrigger></td> */}
                                                            <td>{listValue.expense_mileage_odometer_start}</td>
                                                            <td>{listValue.expense_mileage_odometer_end}</td>
                                                            {/* <td>{listValue.expense_mileage_odometer_end - listValue.expense_mileage_odometer_start}</td> */}
                                                            <td>{listValue.expense_mileage_no_of_miles}</td>
                                                            <td>{listValue.expense_mileage_amount}</td>
                                                            
                                                            {/* <td>{getAmount(listValue.expense_mileage_dt, listValue.expense_mileage_odometer_end, listValue.expense_mileage_odometer_start)}</td> */}
                                                            {listValue.expense_mileage_status == 'S' ?
                                                            <td>
                                                                <OverlayTrigger
                                                                    key='bottom'
                                                                    placement='bottom'

                                                                    overlay={
                                                                        <Tooltip id='tooltip-bottom'>
                                                                            Edit
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <img src={'/assets/icons/edit-small.svg'} onClick={() => openEditOverlay(listValue)}></img>

                                                                </OverlayTrigger>
                                                            
                                                                <OverlayTrigger
                                                                    key='bottom'
                                                                    placement='bottom'

                                                                    overlay={
                                                                        <Tooltip id='tooltip-bottom'>
                                                                            Delete
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <img src={'/assets/icons/delete.svg'} onClick={() => openDeleteOverlay(listValue)}></img>

                                                                </OverlayTrigger>
                                                            </td>
                                                             : <td>{listValue.expense_mileage_status == 'P' ? (<span className="badge badge-success">PAID</span>) : listValue.expense_mileag_status == 'V' ? (<span className="badge badge-purple">VERIFIED</span>) : (<span></span>)}</td>}  
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                        {dashboardList?.length <= 0 &&
                                            <div className='no-records'>No Data found</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

            {dashboardList?.length > 0 &&
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={apipagination.countRows}
                    pageSize={recordsPerPage}
                    pageRange={apipagination.pageRange}
                    setCurrentPage={setCurrentPage}
                    setRecordsPerPage={setRecordsPerPage}
                    totalAmount={formattedValue1}
                    onPageChange={page => setCurrentPage(page)}
                />
            }
        </>
    );
}