import Button from 'react-bootstrap/Button';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { mileageReimbursementDashboardActions, mileageReimbursementDeleteRecordActions} from "../_store";
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
export function MileageReimbursementDeleteModal(props) {
    const [excel, setExcel] = useState(false);
    const dispatch = useDispatch();
    let  deleteReceipt  = useSelector(x => x.deleteRecord);
// console.log(deleteReceipt, 1)
    const cancelModal = () => {
        props.onHide();
      
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(mileageReimbursementDeleteRecordActions.deleteRecord(props.modaldata));
        // console.log(deleteReceipt.success)
       

    }
    useEffect(() => {
        if(deleteReceipt.success) {
            props.onHide();
          
            const copyLocalObj = Object.assign({}, JSON.parse(localStorage.getItem('mileageFilterObjValues')));
            dispatch(mileageReimbursementDashboardActions.getAll(copyLocalObj));
        }
    },[deleteReceipt])
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="export-modal filter-modal delete-modal"
            >
            <h4>Delete Record</h4>
            <Modal.Body>
                <div className="form-overlay">
                    <div className="receipts edit-receipts">
                        {/* {excelExport.loading &&
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>} */}

                        <Form onSubmit={handleSubmit}>
                            <div className='export-dashboard'>
         <p>Are you sure you want to delete this record?</p>

                            </div>
                            <div className='flex'>
                            <Button type="submit"> Yes</Button>
                            <Button onClick={cancelModal} className="close-button"> No</Button>
                                {/* <OverlayTrigger
                                    key='bottom-filter'
                                    placement='bottom'
                                    overlay={
                                        <Tooltip id='tooltip-filter'>
                                            Delete
                                        </Tooltip>
                                    }
                                >
                                    <Button type="submit"> <img src={'/assets/icons/delete.svg'} /></Button>

                                </OverlayTrigger>
                                <OverlayTrigger
                                    key='bottom-cancel'
                                    placement='bottom'
                                    overlay={
                                        <Tooltip id='tooltip-cancel'>
                                            Cancel
                                        </Tooltip>
                                    }
                                >
                                    <Button onClick={cancelModal} className="close-button"> <img src={'/assets/icons/close.svg'} /></Button>
                                </OverlayTrigger> */}
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal.Body>

        </Modal>
    );

}