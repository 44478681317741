import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './MileageReimbursementForm.scss';
import { mileageRatesActions } from '../../_store';
import { clientsActions } from '../../_store';
import Select from 'react-select';
import { Navigate } from 'react-router-dom';
import { history } from '../../_helpers';
import CurrencyInput from 'react-currency-input-field';
import Spinner from 'react-bootstrap/Spinner';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import moment from "moment";

const billTo = [
  { value: 'C', label: 'Client', key: 1 },
  { value: 'T', label: 'Tek', key: 2 }
]

export { MileageReimbursementForm };

function MileageReimbursementForm() {
  const [isClearable, setIsClearable] = useState(true);

  const [billOption, selectBillTo] = useState();
  // const [submitCheck, setSubmit] = useState(false);
  let [loading, setLoading] = useState(false);
  const [saveError, setError] = useState('');
  const { user: authUser } = useSelector(x => x.auth);
  const [response, setResponse] = useState(false);
  const [showNegativeError, setShowNegativeError] = useState([{ index: null, value: false }]);


  const [formFields, setFormFields] = useState([{
    expense_mileage_id: 0,
    expense_mileage_dt: "",
    expense_mileage_from: "",
    expense_mileage_to: "",
    expense_mileage_odometer_start: "",
    expense_mileage_odometer_end: "",
    expense_mileage_memo: "",
    expense_mileage_status: 'S',
    expense_mileage_user_id: authUser.user.user_id,
    // expense_mileage_bill_to: "",
    // expense_mileage_bill_to_client_id: "",
    // expense_mileage_bill_status: 'P',
    client_inv_id: "",
    expense_mileage_reviewed: 0,
    expense_mileage_no_of_miles: "",
    emp_pmt_id: "",
    expense_mileage_amount: ""
  }]);
  const handleAddFields = () => {
    const values = [...formFields];
    values.push({
      expense_mileage_id: 0,
      expense_mileage_dt: "",
      expense_mileage_from: "",
      expense_mileage_to: "",
      expense_mileage_odometer_start: "",
      expense_mileage_odometer_end: "",
      expense_mileage_memo: "",
      expense_mileage_status: 'S',
      expense_mileage_user_id: authUser.user.user_id,
      // expense_mileage_bill_to: "",
      // expense_mileage_bill_to_client_id: "",
      // expense_mileage_bill_status: 'P',
      client_inv_id: "",
      expense_mileage_reviewed: 0,
      expense_mileage_no_of_miles: "",
      emp_pmt_id: "",
      expense_mileage_amount: ""
    });
    setFormFields(values);
  };

  const handleDeleteFields = (index) => {
    const values = [...formFields];
    values.splice(index, 1);
    setFormFields(values);
  };

  // mileage References

  const dateRef = useRef();
  const fromRef = useRef();
  const toRef = useRef();
  const mileRef = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();

    const found = formFields.find(x => x.expense_mileage_no_of_miles <= 0);
    if (!found) {
      apiCall(formFields);
    }
    console.log("Form submitted: ", formFields);
  };

  const billToRef = useRef();
  const clientRef = useRef();

  if (!authUser) {
    return <Navigate to="/login" state={{ from: history.location }} />
  }

  const current = new Date();
  //const Todaysdate = `${current.getFullYear()}-${(current.getMonth() > 9 ? (current.getMonth() + 1) : '0' + (current.getMonth() + 1))}-${current.getDate() > 9 ? current.getDate() : '0' + current.getDate()}`;
  // const [currentDate, setDate] = useState(Todaysdate);
  const Todaysdate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
  const [odoMeterStart, setOdoMeterStart] = useState([]);
  const [odoMeterEnd, setOdoMeterEnd] = useState([]);
  // const [noOfMiles, setNoOfMiles] = useState(null);
  const dispatch = useDispatch();
  const { clients } = useSelector(x => x.clients);
  const { mileageRates } = useSelector(x => x.mileageRates);

  useEffect(() => {
    dispatch(clientsActions.getAll());
    dispatch(mileageRatesActions.getAll());

  }, []);

  //Bill dropdown
  const selectBill = (index, e) => {
    handleInputChange(index, e, "B")
    if (e) {
      selectBillTo(e.key)
    } else {
      selectBillTo(undefined)
    }
  };

  const handleInputChange = (index, event, x) => {
    const values = [...formFields];
    if (x == "B") {
      // values[index].expense_mileage_bill_to = event ? event.value : null;
    } else if (x == 'C') {
      // values[index].expense_mileage_bill_to_client_id = event ? event.client_id : null;
    } else {
      values[index][event.target.name] = event ? event.target.value : null;
      const { name, value } = event.target;
      if (name === "expense_mileage_odometer_end" || name === "expense_mileage_odometer_start" || name == "expense_mileage_dt") {
        const end = parseInt(values[index].expense_mileage_odometer_end);
        const start = parseInt(values[index].expense_mileage_odometer_start);
        if (end >= 0 && start >= 0) {
          values[index].expense_mileage_no_of_miles = end - start;
        }
        for (let i = 0; i < mileageRates.length; i++) {
          const startDate = new Date(moment(mileageRates[i].mileage_rate_start_dt).format('YYYY-MM-DD'));
          const endDate = new Date(moment(mileageRates[i].mileage_rate_end_dt).format('YYYY-MM-DD'));
          const dateToCheck = new Date(values[index].expense_mileage_dt);

          if (dateToCheck >= startDate && dateToCheck <= endDate) {
            values[index].expense_mileage_amount = (values[index].expense_mileage_no_of_miles * mileageRates[i].mileage_rate).toFixed(2);
          }
        }
      }
      values[index][name] = value;
    }
    setFormFields(values);
  };
  
  const onChangeOdoMeterStart = (index, e) => {
    const newValue = e.target.value;
    
    // const value = e.target.value?.replace(/\D/g, "");
    if (/^[+]?(?!0$)\d+$/.test(newValue) || newValue === '') {
    setOdoMeterStart([
      {
        index: index,
        value: newValue
      }
    ]);
    handleInputChange(index, e, "OS");
  }
  };

  const onChangeOdoMeterEnd = (index, e) => {
    const newValue = e.target.value;
    
    // const value = e.target.value?.replace(/\D/g, "");
    if (/^[+]?(?!0$)\d+$/.test(newValue) || newValue === '') {
    setOdoMeterEnd([{
      index: index,
      value: newValue
    }]);
    handleInputChange(index, e, "OE");
  }
  };

  function apiCall(formFields) {
    document.body.style.opacity = 0.5;
    const baseUrl = `${process.env.REACT_APP_API_URL}/MileageReimbursement/`;
    fetch(baseUrl + 'Save', {
      method: 'POST',
      headers: {

        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authUser.jwtToken
      },
      body: JSON.stringify(formFields)
    }).then((response) => {

      setLoading(true);
      if (response.ok) {
        setLoading(false);
        setResponse(true);
        setTimeout(() => {
          setResponse(false);

          window.location.reload();
          document.body.style.opacity = 1;
        }, 2000);
        console.log("success")
      } else {
        setError(response.statusText);
        setLoading(false);
      }
    });
  }



  return (
    <>

      <div className="App">
        <div className="mileage">
          <div className='dash-icon'>
            <div></div>
            <h6 className='upload-receipt-title'>EMPLOYEE MILEAGE REIMBURSEMENT</h6>
            <OverlayTrigger
              key='bottom'
              placement='bottom'
              overlay={
                <Tooltip id='tooltip-bottom'>
                  Mileage Reimbursement Dashboard
                </Tooltip>
              }
            >
              <a href="/MileageReimbursementDashboard" className='dashboard-icon-upload'><img src={'/assets/images/dashboard2.png'} /></a>
            </OverlayTrigger>

          </div>
          {loading &&
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>}
          {response &&
            <div className='flex toast-msg'>
              <div className="alert alert-success" role="alert">
                Mileage reimbursement form submitted successfully
              </div>
            </div>}


          <main>
            <div className="container">
              <div className="">
                <div className="receipts-form">
                  <form onSubmit={handleSubmit}>
                    {formFields.map((field, index) => (
                      <div className="field-group second-section" key={index}>
                        <div className='field-element' key={'empty' + index}>
                          <div className='field'></div>
                        </div>
                        <div className="field-element" key={'date' + index}>
                          <div className="field">
                            <label htmlFor="expense_mileage_dt" className="label">Date<span className="mandatory-field">* </span></label>
                            <input name="expense_mileage_dt" ref={dateRef} id="date" type="date" min="2022-12-27" onChange={(event) => handleInputChange(index, event, 'd')} required max={Todaysdate}
                              value={field.expense_mileage_dt ? moment(field.expense_mileage_dt).format('YYYY-MM-DD') : ''} />
                          </div>
                        </div>
                        <div className="field-element" key={'from' + index}>
                          <label htmlFor="expense_mileage_from" className="label">From<span className="mandatory-field">* </span></label>
                          <div className="field">
                            <input name="expense_mileage_from" id="from" type="text" required ref={fromRef} onChange={(event) => handleInputChange(index, event, "F")}
                              value={field.expense_mileage_from} />
                          </div>
                        </div>
                        <div className="field-element" key={'to' + index}>
                          <label htmlFor="expense_mileage_to" className="label">To<span className="mandatory-field">* </span></label>
                          <div className="field">
                            <input name="expense_mileage_to" id="to" type="text" required ref={toRef} onChange={(event) => handleInputChange(index, event, "T")} value={field.expense_mileage_to} />
                          </div>
                        </div>
                        {/* <div className="field-element" key={'bill' + index}>
                          <div className="field">
                            <label htmlFor="memo" className="label">Bill To<span className="mandatory-field">* </span></label>
                            <Select options={billTo}
                              onChange={(e) => selectBill(index, e)}
                              isClearable={isClearable}
                              className="form-custom-class"
                              classNamePrefix="form-custom-class-prefix"
                              name="expense_mileage_bill_to"
                              required
                              ref={billToRef}
                              value={billTo.find(obj => obj.value == field.expense_mileage_bill_to)}
                            />
                          </div></div> */}
                        {/* {field.expense_mileage_bill_to == 'C' ? (

                          <div className="field-element" key={'client' + index}>
                            <div className="field">
                              <label htmlFor="expense_mileage_bill_to_client_id" className="label">Client<span className="mandatory-field">* </span></label>
                              {clients.length > 0 &&
                                <Select options={clients}
                                  isClearable={isClearable}
                                  getOptionLabel={e => e.client_name}
                                  getOptionValue={e => e.client_id}
                                  required
                                  onChange={(event) => handleInputChange(index, event, "C")}
                                  className="form-custom-class"
                                  classNamePrefix="form-custom-class-prefix"
                                  name="expense_mileage_bill_to_client_id"
                                  ref={clientRef}
                                  value={clients.find(obj => obj.client_id == field.expense_mileage_bill_to_client_id)}
                                />}
                            </div>
                          </div>) :
                          billOption == 2 ? (
                            <div className="field-element" key="department">

                            </div>) : <div className='field-element'>
                            <div className='field'></div>
                          </div>} */}
                        <div className="field-element" key={'os' + index}>
                          <label htmlFor="expense_mileage_odometer_start" className="label">Odometer Start<span className="mandatory-field">* </span></label>
                          <div className='info-icon'>
                      <OverlayTrigger
                        key='bottom'
                        placement='bottom'

                        overlay={
                          <Tooltip id='tooltip-bottom'>
                           Actual odometer readings are only allowed. 0 & Negative numbers are not allowed.
                          </Tooltip>
                        }
                      ><img className='' src={'/assets/images/info.png'} /></OverlayTrigger>
                    </div>
                          <div className="field">
                            <input name="expense_mileage_odometer_start" id="odometer_start" type="text" required
                              onChange={(event) => onChangeOdoMeterStart(index, event, "OS")}
                              value={index == odoMeterStart.index ? odoMeterStart.value : field.expense_mileage_odometer_start?.replace(/\D/g, "")} />
                          </div>
                          {/* {field.expense_mileage_odometer_start == 0 &&
                                <span className="error-msg">Please enter &gt; 0 value</span>} */}

                        </div>
                        <div className="field-element" key={'oe' + index}>
                          <label htmlFor="expense_mileage_odometer_end" className="label">Odometer End<span className="mandatory-field">* </span></label>
                          <div className='info-icon'>
                      <OverlayTrigger
                        key='bottom'
                        placement='bottom'

                        overlay={
                          <Tooltip id='tooltip-bottom'>
                           Actual odometer readings are only allowed. 0 & Negative numbers are not allowed.
                          </Tooltip>
                        }
                      ><img className='' src={'/assets/images/info.png'} /></OverlayTrigger>
                    </div>
                          <div className="field">
                            <input name="expense_mileage_odometer_end" id="odometer_end" type="text" required
                              onChange={(event) => onChangeOdoMeterEnd(index, event, "OE")}
                              className={field.expense_mileage_no_of_miles < 0 ? 'invalid' : ''}
                              value={index == odoMeterEnd.index ? odoMeterEnd.value : field.expense_mileage_odometer_end?.replace(/\D/g, "")} />
                          </div>

                        </div>
                        <div className="field-element" key={'miles' + index}>
                          <label htmlFor="expense_mileage_no_of_miles" className="label">No of Miles</label>
                          <div className="field">
                            <input name="expense_mileage_no_of_miles" id="mileNumber" ref={mileRef} type="text"
                              value={field.expense_mileage_no_of_miles} readOnly />
                          </div>
                        </div>
                        <div className="field-element amount" key={'amount' + index}>
                          <div className="field">
                            <label htmlFor="expense_mileage_amount" className="label">Amount</label>

                            <CurrencyInput
                              id="input-example-amount"
                              name="expense_mileage_amount"
                              decimalsLimit={2}
                              prefix="$ "
                              allowDecimals
                              allowNegativeValue={false}
                              disabled={true}
                              required
                              value={field.expense_mileage_amount}
                              key='amount_key'
                              readOnly
                            />

                          </div>
                        </div>
                        <div className='field-element' key={'delete' + index}>
                          <div className='field'>
                            <div className='delete-icon'>
                              {index > 0 &&
                                <OverlayTrigger
                                  key='bottom'
                                  placement='bottom'
                                  overlay={
                                    <Tooltip id='tooltip-bottom'>
                                      Delete
                                    </Tooltip>
                                  }
                                >
                                  <img src={'/assets/icons/delete.svg'} onClick={() => handleDeleteFields(index)}></img>
                                </OverlayTrigger>}
                            </div></div>
                        </div>
                       
                        <div className="error-section">
                          {field.expense_mileage_no_of_miles !== "" && field.expense_mileage_no_of_miles <= 0 &&
                            <span className="error-msg">Odometer End should be greater than Odometer Start</span>
                          }

                        </div>
                      </div>
                    ))}
                    <div>

                    </div>
                    <div className='add-icon'>
                      <OverlayTrigger
                        key='bottom'
                        placement='bottom'

                        overlay={
                          <Tooltip id='tooltip-bottom'>
                            Add
                          </Tooltip>
                        }
                      ><img className={showNegativeError.value ? 'disabled' : ''} src={'/assets/images/add.png'} onClick={handleAddFields} /></OverlayTrigger>
                    </div>
                    <div className='button-message'>
                      <div className="upload-images-div">
                        <div className="field-group">
                          <div className="field">
                            <div className='receive-save-wrapper'>
                              <div className="sign-btn flex">
                                <button type="submit" className="btn btn-primary receipt-save">
                                  Submit</button>

                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="field-group">

                        <div className="error-msg alert alert-success mt-3 mb-0">{saveError}</div>
                      </div>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </main>

        </div>
      </div>

    </>
  );
}


