import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './MileageReimbursementEditReceipt.scss';
import { mileageReimbursementDashboardActions, userActions } from '../../_store';
import { creditcardActions } from '../../_store';
import { clientsActions } from '../../_store';
import { mileageRatesActions } from '../../_store';
import { payeeActions } from '../../_store';
import { departmentActions } from '../../_store';
import { purchaseTypeActions } from '../../_store';
import Select from 'react-select';
import { Navigate } from 'react-router-dom';
import { history } from '../../_helpers';
import CurrencyInput from 'react-currency-input-field';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Spinner from 'react-bootstrap/Spinner';
import moment from "moment";

const billTo = [
  { value: 'C', label: 'Client', key: 1 },
  { value: 'T', label: 'Tek', key: 2 },
]

export { MileageReimbursementEditReceipt };

function MileageReimbursementEditReceipt(props) {
  const [isClearable, setIsClearable] = useState(true);
  const [billOption, selectBillTo] = useState();
  const [saveError, setError] = useState('');
  let [loading, setLoading] = useState(false);
  const { user: authUser } = useSelector(x => x.auth);
  const [response, setResponse] = useState(false);


  const [noOfMiles, setNoOfMiles] = useState(null);
  const [calAmount, setCalAmount] = useState();

  let bindObj;
  var transDate;
  bindObj = props.formProps.modaldata;
  const [odoMeterEnd, setOdoMeterEnd] = useState(bindObj.expense_mileage_odometer_end);
  const [odoMeterStart, setOdoMeterStart] = useState(bindObj.expense_mileage_odometer_start);
  const [formFields, setFormFields] = useState([{
    expense_mileage_id: bindObj.expense_mileage_id ? bindObj.expense_mileage_id : null,
    expense_mileage_dt: bindObj.expense_mileage_dt ? bindObj.expense_mileage_dt : currentDate,
    expense_mileage_from: bindObj.expense_mileage_from ? bindObj.expense_mileage_from : '',
    expense_mileage_to: bindObj.expense_mileage_to ? bindObj.expense_mileage_to : '',
    expense_mileage_odometer_start: bindObj.expense_mileage_odometer_start ? bindObj.expense_mileage_odometer_start : null,
    expense_mileage_odometer_end: bindObj.expense_mileage_odometer_end ? bindObj.expense_mileage_odometer_end : null,
    expense_mileage_memo: bindObj.expense_mileage_memo ? bindObj.expense_mileage_memo : "",
    expense_mileage_status: bindObj.expense_mileage_status ? bindObj.expense_mileage_status : "",
    expense_mileage_user_id: bindObj.expense_mileage_user_id ? bindObj.expense_mileage_user_id : userValue,
    // expense_mileage_bill_to: bindObj.expense_mileage_bill_to ? bindObj.expense_mileage_bill_to : "",
    expense_mileage_no_of_miles: bindObj.expense_mileage_no_of_miles ? bindObj.expense_mileage_no_of_miles : null,
    expense_mileage_amount: bindObj.expense_mileage_amount ? bindObj.expense_mileage_amount : null,
    // expense_mileage_bill_to_client_id: bindObj.expense_mileage_bill_to_client_id ? bindObj.expense_mileage_bill_to_client_id : null,
    client_inv_id: null,
    expense_mileage_reviewed: 0,
    emp_pmt_id: null,
  }]);
  
  const dateRef = useRef();
  const fromRef = useRef();
  const toRef = useRef();
  const odometerstartRef = useRef();
  const odometerendRef = useRef();
  const mileRef = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();
    const found = formFields.find(x => x.expense_mileage_no_of_miles <= 0);
    if (!found) {
      apiCall(formFields);
    }
    console.log("Form submitted: ", formFields);
  };
  const billToRef = useRef();
  const clientRef = useRef();
  if (!authUser) {
    return <Navigate to="/login" state={{ from: history.location }} />
  }

  let userDefaultValue = {
    user_active: authUser.user.user_active,
    user_fname: authUser.user.user_fname,
    user_id: authUser.user.user_id,
    user_lname: authUser.user.user_lname,
    user_mname: authUser.user.user_mname
  }
  const [userValue, setUserValue] = useState(userDefaultValue.user_id);
  const current = new Date();
  const Todaysdate = `${current.getFullYear()}-${(current.getMonth() >= 9 ? (current.getMonth() + 1) : '0' + (current.getMonth() + 1))}-${current.getDate() >= 9 ? current.getDate() : '0' + current.getDate()}`;
  const [amountValue, setAmount] = useState(null);
  const dispatch = useDispatch();
  const { users } = useSelector(x => x.users);

  const { clients } = useSelector(x => x.clients);
  const { mileageRates } = useSelector(x => x.mileageRates);
  
  useEffect(() => {
    getAmount()
  }, [mileageRates])

  transDate = moment(bindObj.expense_mileage_dt).format('YYYY-MM-DD')
  const [currentDate, setDate] = useState(transDate);

  useEffect(() => {
    if (bindObj.expense_mileage_bill_to == 'C') {
      selectBillTo(1)
    } else if (bindObj.expense_mileage_bill_to == 'T') {
      selectBillTo(2)
    } 
    // else if (bindObj.expense_mileage_bill_to == 'P') {
    //   selectBillTo(3)
    // }
   
    dispatch(userActions.getAll());
    // dispatch(creditcardActions.getAll());
    dispatch(clientsActions.getAll());
    dispatch(mileageRatesActions.getAll());
    // dispatch(payeeActions.getAll());
    // dispatch(departmentActions.getAll());
    // dispatch(purchaseTypeActions.getAll());
  }, []);

  //Bill dropdown
  const selectBill = (e) => {
    if (e) {
      selectBillTo(e.key);
      handleInputChange(e, "B")
      
    } else {
      selectBillTo(undefined)
      handleInputChange(e, "B")
    }
  };

  const handleInputChange = (event, x) => {
    const values = [...formFields];
    
    if (x == "B") {
      // values[0].expense_mileage_bill_to = event ? event.value : null;
    } else if (x == 'C') {
      // values[0].expense_mileage_bill_to_client_id = event ? event.client_id : null;
    } else {
      values[0][event.target.name] = event ? event.target.value : null;
      const { name, value } = event.target;
      if (name === "expense_mileage_odometer_end" || name === "expense_mileage_odometer_start" || name == "expense_mileage_dt") {
        const end = parseInt(values[0].expense_mileage_odometer_end);
        const start = parseInt(values[0].expense_mileage_odometer_start);

        if (end >= 0 && start >= 0) {
          values[0].expense_mileage_no_of_miles = end - start;
          setNoOfMiles(end - start)
        }
        for (let i = 0; i < mileageRates.length; i++) {
          const startDate = new Date(moment(mileageRates[i].mileage_rate_start_dt).format('YYYY-MM-DD'));
          const endDate = new Date(moment(mileageRates[i].mileage_rate_end_dt).format('YYYY-MM-DD'));
          const dateToCheck = new Date(values[0].expense_mileage_dt);

          if (dateToCheck >= startDate && dateToCheck <= endDate) {
            values[0].expense_mileage_amount = values[0].expense_mileage_no_of_miles * mileageRates[i].mileage_rate;
            setAmount(values[0].expense_mileage_no_of_miles * mileageRates[i].mileage_rate)
          }
        }
      }
      values[0][name] = value;
    }
    setFormFields(values);
  };

  function getAmount() {
    for (let i = 0; i < mileageRates.length; i++) {
      const startDate = new Date(moment(mileageRates[i].mileage_rate_start_dt).format('YYYY-MM-DD'));

      const endDate = new Date(moment(mileageRates[i].mileage_rate_end_dt).format('YYYY-MM-DD'));
      const dateToCheck = new Date(bindObj.expense_mileage_dt);

      if (dateToCheck >= startDate && dateToCheck <= endDate) {
        const value = ((bindObj.expense_mileage_odometer_end - bindObj.expense_mileage_odometer_start) * mileageRates[i].mileage_rate);
        setCalAmount(value.toFixed(2))
      }
    }
  }

  const onChangeOdoMeterStart = (e) => {
    // const value = e.target.value?.replace(/\D/g, "");
    const newValue = e.target.value;
    if (/^[+]?(?!0$)\d+$/.test(newValue) || newValue === '') {
      setOdoMeterStart(newValue);
      handleInputChange(e, "OS");
    }
   
  
  };

  const onChangeOdoMeterEnd = (e) => {
    // const value = e.target.value?.replace(/\D/g, "");
    // setOdoMeterEnd(value);
    // handleInputChange(e, "OE");
    const newValue = e.target.value;
    
    // const value = e.target.value?.replace(/\D/g, "");
    if (/^[+]?(?!0$)\d+$/.test(newValue) || newValue === '') {
      setOdoMeterEnd(newValue);
      handleInputChange(e, "OE");
  }
  };

  function apiCall(formFields) {
    document.body.style.opacity = 0.5;
    const baseUrl = `${process.env.REACT_APP_API_URL}/MileageReimbursement/`;
    fetch(baseUrl + 'Save', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authUser.jwtToken
      },
      body: JSON.stringify(formFields)
    }).then((response) => {
      setLoading(true);
      if (response.ok) {
        setLoading(false);
        props.formProps.onHide();
        document.body.style.opacity = 1;
        if (localStorage.getItem('mileageFilterObjValues')) {
          const reloadData = JSON.parse(localStorage.getItem('mileageFilterObjValues'))
          dispatch(mileageReimbursementDashboardActions.getAll(reloadData));
        }
      } else {
        setError(response.statusText);
        setLoading(false);
      }
    });
  }

  return (
    <>

      <div className="form-overlay">
        <div className="receipts edit-mileage">

          <div className='dash-icon'>
            <div></div>
            <h6 className='dash-title edit-receipt-title'>EMPLOYEE MILEAGE REIMBURSEMENT</h6>
            <OverlayTrigger
              key='bottom'
              placement='bottom'
              overlay={
                <Tooltip id='tooltip-bottom'>
                  Mileage Reimbursement Dashboard
                </Tooltip>
              }
            >
              <a href="/MileageReimbursementDashboard" className='dashboard-icon-upload'><img src={'/assets/images/dashboard2.png'} /></a>
            </OverlayTrigger>
          </div>

          {response &&
            <div className='flex toast-msg'>
              <div className="alert alert-success" role="alert">
                Receipt uploaded successfully
              </div>
            </div>}

          {(clients.loading) &&
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>}
          {users.length > 0 &&
            (bindObj.reimbursement_trans_bill_to_client_id ? clients.length > 0 : true) &&

            <main>
              <div className="container-edit container">
                <div className="">
                  <div className="receipts-form">
                    <form onSubmit={handleSubmit}>
                      <div className="field-group second-section">
                        <div className="field-element">
                          <div className="field">
                            <label htmlFor="expense_mileage_dt" className="label">Date<span className="mandatory-field">* </span></label>
                            <input name="expense_mileage_dt" ref={dateRef} id="date" type="date" min="2022-12-27" defaultValue={currentDate}
                              onChange={(event) => handleInputChange(event, 'd')} max={Todaysdate}/>
                          </div>
                        </div>
                        <div className="field-element">
                          <label htmlFor="expense_mileage_from" className="label">From<span className="mandatory-field">* </span></label>
                          <div className="field">
                            <input name="expense_mileage_from" id="from" type="text" required ref={fromRef} defaultValue={bindObj.expense_mileage_from}
                              onChange={(event) => handleInputChange(event, "F")} />
                          </div>
                        </div>
                        <div className="field-element">
                          <label htmlFor="expense_mileage_to" className="label">To<span className="mandatory-field">* </span></label>
                          <div className="field">
                            <input name="expense_mileage_to" id="to" type="text" required ref={toRef} defaultValue={bindObj.expense_mileage_to}
                              onChange={(event) => handleInputChange(event, "T")} />
                          </div>
                        </div>
                        {/* <div className="field-element">
                          <div className="field">
                            <label htmlFor="expense_mileage_bill_to" className="label">Bill To<span className="mandatory-field">* </span></label>
                            <Select options={billTo}
                              onChange={(e) => selectBill(e)}
                              isClearable={isClearable}
                              className="form-custom-class"
                              classNamePrefix="form-custom-class-prefix"
                              name="expense_mileage_bill_to"
                              defaultValue={billTo.find(obj => obj.value == bindObj.expense_mileage_bill_to)}
                              ref={billToRef}
                              required
                            />
                          </div></div> */}
                        {/* {billOption == 1 ? (
                          <div className="field-element" key="client">
                            <div className="field">
                              <label htmlFor="expense_mileage_bill_to_client_id" className="label">Client<span className="mandatory-field">* </span></label>
                              {clients.length > 0 &&
                                <Select options={clients}
                                  isClearable={isClearable}
                                  getOptionLabel={e => e.client_name}
                                  getOptionValue={e => e.client_id}
                                  onChange={(event) => handleInputChange(event, "C")}
                                  defaultValue={clients.find(obj => obj.client_id == bindObj.expense_mileage_bill_to_client_id)}
                                  className="form-custom-class"
                                  classNamePrefix="form-custom-class-prefix"
                                  name="expense_mileage_bill_to_client_id"
                                  ref={clientRef}
                                  required
                                />}
                            </div>
                          </div>) :
                          billOption == 2 ? (
                            <div className="field-element" key="department">

                            </div>) : <div className='field-element'>
                            <div className='field'></div>
                          </div>} */}
                      </div>
                      <div className="field-group second-section">
                        <div className="field-element">
                          <label htmlFor="expense_mileage_odometer_start" className="label">Odometer Start<span className="mandatory-field">* </span></label>
                          <div className='info-icon'>
                      <OverlayTrigger
                        key='bottom'
                        placement='bottom'

                        overlay={
                          <Tooltip id='tooltip-bottom'>
                           Actual odometer readings are only allowed. 0 & Negative numbers are not allowed.
                          </Tooltip>
                        }
                      ><img className='' src={'/assets/images/info.png'} /></OverlayTrigger>
                    </div>
                          <div className="field">
                            <input name="expense_mileage_odometer_start" id="odometer_start" type="text" required ref={odometerstartRef} 
                            // defaultValue={bindObj.expense_mileage_odometer_start !== 0 ? bindObj.expense_mileage_odometer_start : odoMeterStart}
                            value={ odoMeterStart}
                             // onChange={(event) => onChangeOdoMeterStart(event, "OS")}
                             onChange={onChangeOdoMeterStart}
                            />
                          </div>
                        </div>
                        <div className="field-element">
                          <label htmlFor="expense_mileage_odometer_end" className="label">Odometer End<span className="mandatory-field">* </span></label>
                          <div className='info-icon'>
                      <OverlayTrigger
                        key='bottom'
                        placement='bottom'

                        overlay={
                          <Tooltip id='tooltip-bottom'>
                           Actual odometer readings are only allowed. 0 & Negative numbers are not allowed.
                          </Tooltip>
                        }
                      ><img className='' src={'/assets/images/info.png'} /></OverlayTrigger>
                    </div>
                          <div className="field">
                            <input name="expense_mileage_odometer_end" id="odometer_end" type="text" required ref={odometerendRef} 
                            value={odoMeterEnd}
                              className={formFields[0].expense_mileage_no_of_miles < 0 ? 'invalid' : ''}
                              onChange={onChangeOdoMeterEnd}
                            />
                          </div>
                        </div>
                        <div className="field-element">
                          <label htmlFor="expense_mileage_no_of_miles" className="label">No of Miles</label>
                          <div className="field">
                            <input name="expense_mileage_no_of_miles" id="mileNumber" ref={mileRef} type="text" defaultValue={bindObj.expense_mileage_odometer_end - bindObj.expense_mileage_odometer_start}
                              readOnly value={noOfMiles} />
                          </div>
                        </div>
                        <div className="field-element amount">
                          <div className="field">
                            <label htmlFor="expense_mileage_amount" className="label">Amount</label>

                            <CurrencyInput
                              id="input-example-amount"
                              name="expense_mileage_amount"
                              decimalsLimit={2}
                              prefix="$ "
                              allowDecimals
                              // defaultValue={calAmount ? calAmount : amountValue}
                              allowNegativeValue={false}
                              disabled={true}
                              required 
                              value={amountValue ? amountValue : calAmount}
                              key='amount_key'
                            />

                          </div>
                        </div>
                        <div className='field-element'>
                          <div className='field'></div>
                        </div>
                        <div className="error-section">
                        {formFields[0].expense_mileage_no_of_miles !== "" && formFields[0].expense_mileage_no_of_miles <= 0 && 
                          // {field.expense_mileage_no_of_miles !== "" && field.expense_mileage_no_of_miles <= 0 &&
                            <span className="error-msg">Odometer End should be greater than Odometer Start</span>
                          }

                        </div>
                      </div>
                    
                      <div className='button-message'>
                        <div className="upload-images-div">
                         
                          <div className="field-group">
                            <div className="field">
                              <div className='receive-save-wrapper'>
                                <div className="sign-btn flex">
                                  <button type="submit" className="btn btn-primary receipt-save" disabled={loading}>
                                    Submit</button>
                                  <div className="btn btn-danger receipt-cancel" onClick={props.formProps.onHide}>
                                    Cancel</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="field-group">                       
                          <div className="error-msg alert alert-success mt-3 mb-0">{saveError}</div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </main>}

        </div>
      </div>

    </>
  );
}


